import React, { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { Enviroments } from '../../../../context/Enviroments';
import { LoggedContext } from '../../../../context/LoggedContext';
import { UserContext } from '../../../../context/UserContext';
import { SignaturesContext } from '../../../../context/SignaturesContext';
import 'rc-slider/assets/index.css';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import './signatures.scss';
import Paper from '@mui/material/Paper';
import { Store } from 'react-notifications-component';
import RateReviewIcon from '@mui/icons-material/RateReview';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PdfViewer from './pdfViewer/pdfViewer';
import Skeleton from '@mui/material/Skeleton';
import { Tabs, Tab, Box, TextField, Autocomplete, Button, Typography, Checkbox, FormControlLabel, FormControl, InputLabel, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, IconButton, Tooltip } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import { useDropzone } from 'react-dropzone';
import CloseIcon from '@mui/icons-material/Close';
import UploadSignature from '../../components/modal/uploadSignature';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Backdrop, CircularProgress } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import InfoIcon from '@mui/icons-material/Info';
import GroupIcon from '@mui/icons-material/Group';

import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Signatures = () => {
    const [profileState] = useContext(UserContext);
    const [enviroment] = useContext(Enviroments);
    const [openSign, setOpenSign] = useState(false);
    const handleOpenSign = () => setOpenSign(true);
    const handleCloseSign = () => setOpenSign(false);
    const [signature, setSignature] = useContext(SignaturesContext);

    const [selectedFile, setSelectedFile] = useState(null);
    const [documentName, setDocumentName] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [userSearch, setUserSearch] = useState('');
    const [documentTypePDF, setDocumentTypePDF] = useState('');

    const [notifyUsers, setNotifyUsers] = useState(false);
    const [documentType, setDocumentType] = useState({ value: '', label: '' });
    const documentTypes = [
        { value: 'acta_de_materiales', label: 'Acta de aceptación de materiales y servicios' },
        { value: 'acta_de_servicios', label: 'Acta de aceptación de servicios' },
        { value: 'certificado_de_excepcion', label: 'Certificado de excepción' },
        { value: 'formulario_compra', label: 'Formulario solicitud de compra' },
        { value: 'otro', label: 'otro' },
    ];

    const [tabValue, setTabValue] = useState(0);
    const [innerTabValue, setInnerTabValue] = useState(0);
    const [documentInnerTabValue, setDocumentInnerTabValue] = useState(0);

    const [disponiblesDocuments, setDisponiblesDocuments] = useState([]);
    const [firmadosDocuments, setFirmadosDocuments] = useState([]);
    const [enEsperaDocuments, setEnEsperaDocuments] = useState([]);
    const [finalizadosDocuments, setFinalizadosDocuments] = useState([]);
    const [cerradosDocuments, setCerradosDocuments] = useState([]);

    const [disponiblesSearchTerm, setDisponiblesSearchTerm] = useState('');
    const [firmadosSearchTerm, setFirmadosSearchTerm] = useState('');
    const [enEsperaSearchTerm, setEnEsperaSearchTerm] = useState('');
    const [finalizadosSearchTerm, setFinalizadosSearchTerm] = useState('');
    const [cerradosSearchTerm, setCerradosSearchTerm] = useState('');

    const [pdf, setPdf] = useState(null);
    const [openPDF, setOpenPDF] = useState(false);
    const [docName, setDocName] = useState('');
    const [docId, setDocId] = useState('');
    const [canEdit, setCanEdit] = useState(false);
    const [loadingPDF, setLoadingPDF] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pdfElements, setPdfElements] = useState([]);

    const [canCloseDocument, setCanCloseDocument] = useState(false);
    const [estado, setEstado] = useState('');

    const [openAlertModal, setOpenAlertModal] = useState(false);
    const [alertShown, setAlertShown] = useState(false);

    const [openUsersModal, setOpenUsersModal] = useState(false);
    const [selectedUsersDoc, setSelectedUsersDoc] = useState([]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleInnerTabChange = (event, newValue) => {
        setInnerTabValue(newValue);
    };

    const handleDocumentInnerTabChange = (event, newValue) => {
        setDocumentInnerTabValue(newValue);
    };

    useEffect(() => {
        getDisponibles();
        getFinalizados();
        getEnEspera();
        getCerrados();
        getFirmados();
    }, [signature, profileState]);

    const updateDocumentLists = () => {
        getDisponibles();
        getFinalizados();
        getEnEspera();
        getCerrados();
        getFirmados();
    };

    const handleRemoveUser = (id) => {
        setSelectedUsers(selectedUsers.filter(user => user.id_user !== id));
    };

    useEffect(() => {
        if (selectedFile) {
            setCanEdit(false);
            const fileURL = URL.createObjectURL(selectedFile);
            setPdf(fileURL);
            setOpenPDF(true);
            setPdfElements([]);

            setDocId(null);
            setEstado('');
            setCanCloseDocument(false);

            // Extraer texto del PDF y determinar el tipo de documento
            const reader = new FileReader();
            reader.onload = async function () {
                const typedarray = new Uint8Array(this.result);
                const loadingTask = pdfjs.getDocument({ data: typedarray });
                const pdf = await loadingTask.promise;

                let extractedText = '';
                for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                    const page = await pdf.getPage(pageNum);
                    const textContent = await page.getTextContent();
                    const pageText = textContent.items.map(item => item.str).join(' ');
                    extractedText += pageText + ' ';
                }
                console.log(extractedText)
                // Buscar los labels en el texto extraído
                let foundType = 'otro';
                for (let i = 0; i < documentTypes.length - 1; i++) {
                    const label = documentTypes[i].label.toLowerCase();
                    if (extractedText.toLowerCase().includes(label)) {
                        foundType = documentTypes[i];
                        break;
                    }
                }
                setDocumentType(foundType);
            };
            reader.readAsArrayBuffer(selectedFile);
        }
    }, [selectedFile]);

    useEffect(() => {
        let token = localStorage.getItem('token');
        const headers = {
            "Accept": 'application/json',
            "Authorization": `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*',
        };


        axios.get(enviroment + '/lista-de-usuarios/', { headers })
            .then((response) => {
                const data = response.data;
                setUserOptions(Array.isArray(data) ? data : []);
                console.log(data)
            })
            .catch((error) => {
                console.error('Error get usuarios:', error);
                setUserOptions([]);
            });

    }, [enviroment]);

    const handleFileUpload = async () => {
        if (!selectedFile || !documentName || selectedUsers.length === 0 || !documentType) {
            showAlert("Debe completar todos los campos.", " ", "warning");
            return;
        }
        setLoading(true)
        const data = new FormData();
        data.append('id_emisor', profileState.userId);
        data.append('id_receptores', selectedUsers.map(user => user.id_user));
        data.append('nombre_documento', documentName);
        data.append('data', selectedFile);
        data.append('notificar', notifyUsers);
        data.append('tipo_documento', documentType.value);

        let token = localStorage.getItem('token');
        const headers = {
            "Accept": 'application/json',
            "Authorization": `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*',
        };

        console.log(selectedUsers.map(user => user.id_user));

        await axios.post(enviroment + '/nuevo-documento/', data, { headers })
            .then(response => {
                setLoading(false)
                console.log(response.data)
                setSelectedFile(null);
                setDocumentName('');
                setSelectedUsers([]);
                setUserSearch('');
                setNotifyUsers(false);
                setDocumentType({ value: '', label: '' });
                updateDocumentLists();
                showAlert("Documento subido con éxito.", " ", "success");
                setOpenPDF(false);
                setPdf(null);
            }).catch(error => {
                setLoading(false)
                console.log(error);
                showAlert("Algo salió mal.", " ", "danger");
            })
    };
    const base64toPDF = (data) => {
        const binaryString = window.atob(data);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return new Blob([bytes], { type: 'application/pdf' });
    };

    const handleTogglePDF = async (e, docId, docName, can_edit, estado, tipo_documento) => {
        setLoadingPDF(true);
        setLoading(true)
        setDocId(docId);
        e.preventDefault();
        setDocName(docName);
        if (!openPDF) setOpenPDF(true);
        setCanEdit(can_edit);
        let token = localStorage.getItem("token");
        setDocumentTypePDF(tipo_documento);
        setCanCloseDocument(false);
        setEstado(estado)
        const headers = {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
        };

        await axios.get(`${enviroment}/obtener-documento/${docId}/${docName}/${profileState.userId}`, { headers })
            .then(response => {
                if (response.data !== 0) {
                    console.log(response.data);
                    const file = base64toPDF(response.data[0]);
                    const fileURL = URL.createObjectURL(file);
                    setPdf(fileURL);
                    setPdfElements(response.data[1]);
                    setLoadingPDF(false);
                    setLoading(false)
                }
            }).catch(error => {
                setLoadingPDF(false);
                setLoading(false)
                console.error('Error fetching document:', error);
            });
    };

    const obtenerDocCerrado = async (e, docId, docName, can_edit, estado) => {
        setLoadingPDF(true);
        setLoading(true)
        setDocId(docId);
        e.preventDefault();
        setDocName(docName);
        if (!openPDF) setOpenPDF(true);
        setCanEdit(can_edit);
        setEstado(estado)
        let token = localStorage.getItem("token");
        const headers = {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
        };

        await axios.get(`${enviroment}/descargar-documento/${docId}/${docName}`, { headers })
            .then(response => {
                if (response.data !== 0) {
                    console.log(response.data);
                    const file = base64toPDF(response.data[0]);
                    const fileURL = URL.createObjectURL(file);
                    setPdf(fileURL);
                    setPdfElements(response.data[1]);
                    setLoadingPDF(false);
                    setLoading(false)
                    setCanCloseDocument(true);
                }
            }).catch(error => {
                setLoadingPDF(false);
                setLoading(false)
                console.error('Error fetching document:', error);
            });
    };

    function getDisponibles() {
        setLoading(true);
        const token = localStorage.getItem('token');
        const headers = {
            "Accept": 'application/json',
            "Authorization": `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*',
        };

        return axios(`${enviroment}/documentos-disponibles/${profileState.userId}`, { headers })
            .then((response) => {
                console.log(response);
                setDisponiblesDocuments(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error al obtener documentos disponibles:', error);
                setLoading(false);
            });
    }

    function getFinalizados() {
        const token = localStorage.getItem('token');
        const headers = {
            "Accept": 'application/json',
            "Authorization": `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*',
        };

        return axios(`${enviroment}/documentos-finalizados/${profileState.userId}`, { headers })
            .then((response) => {
                console.log(response);
                setFinalizadosDocuments(response.data);
            })
            .catch((error) => {
                console.error('Error al obtener documentos finalizados:', error);
            });
    }

    function getEnEspera() {
        const token = localStorage.getItem('token');
        const headers = {
            "Accept": 'application/json',
            "Authorization": `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*',
        };

        return axios(`${enviroment}/documentos-en-espera/${profileState.userId}`, { headers })
            .then((response) => {
                console.log(response);
                setEnEsperaDocuments(response.data);
            })
            .catch((error) => {
                console.error('Error al obtener documentos en espera:', error);
            });
    }

    function getCerrados() {
        const token = localStorage.getItem('token');
        const headers = {
            "Accept": 'application/json',
            "Authorization": `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*',
        };

        return axios(`${enviroment}/documentos-cerrados/${profileState.userId}`, { headers })
            .then((response) => {
                console.log(response);
                setCerradosDocuments(response.data);
            })
            .catch((error) => {
                console.error('Error al obtener documentos cerrados:', error);
            });
    }

    function getFirmados() {
        const token = localStorage.getItem('token');
        const headers = {
            "Accept": 'application/json',
            "Authorization": `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*',
        };

        return axios(`${enviroment}/documentos-firmados/${profileState.userId}`, { headers })
            .then((response) => {
                console.log(response);
                setFirmadosDocuments(response.data);
            })
            .catch((error) => {
                console.error('Error al obtener documentos firmados:', error);
            });
    }

    const onDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setSelectedFile(acceptedFiles[0]);
            setDocumentName(acceptedFiles[0].name);
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'application/pdf',
        multiple: false
    });

    useEffect(() => {
        if (selectedFile) {
            setCanEdit(false);
            const fileURL = URL.createObjectURL(selectedFile);
            setPdf(fileURL);
            setOpenPDF(true);
            setPdfElements([]);

            setDocId(null);
            setEstado('');
            setCanCloseDocument(false);
        }
    }, [selectedFile]);

    const showAlert = (title, message, type) => {
        Store.addNotification({
            title: title,
            message: message,
            type: type,
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true
            }
        });
    };
    const dateColumns = ['fecha'];

    const handleClosePDF = (message, type) => {
        setOpenPDF(false);
        showAlert(message, '', type);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date)) return dateString;
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const filteredDisponiblesDocuments = (disponiblesDocuments || []).filter(doc => doc.nombre_documento.toLowerCase().includes(disponiblesSearchTerm.toLowerCase()));
    const filteredFirmadosDocuments = (firmadosDocuments || []).filter(doc => doc.nombre_documento.toLowerCase().includes(firmadosSearchTerm.toLowerCase()));
    const filteredEnEsperaDocuments = (enEsperaDocuments || []).filter(doc => doc.nombre_documento.toLowerCase().includes(enEsperaSearchTerm.toLowerCase()));
    const filteredCerradosDocuments = (cerradosDocuments || []).filter(doc => doc.nombre_documento.toLowerCase().includes(cerradosSearchTerm.toLowerCase()));
    const filteredFinalizadosDocuments = (finalizadosDocuments || []).filter(doc => doc.nombre_documento.toLowerCase().includes(finalizadosSearchTerm.toLowerCase()));

    const getDisplayColumns = (documents) => {
        if (documents.length === 0) return [];
        const excludeColumns = ['id_documento', 'id_emisor', 'documentob64', 'id_user', 'documento_b64', 'usuarios'];
        return Object.keys(documents[0]).filter(column => !excludeColumns.includes(column));
    };
    const formatColumnName = (column) => {
        return column.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
    };

    useEffect(() => {
        if (disponiblesDocuments.length > 0 && !alertShown) {
            setOpenAlertModal(true);
            setAlertShown(true);
        }
    }, [disponiblesDocuments]);

    return (
        <div className="signatures" >
            <Backdrop style={{ zIndex: 9999 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="signatures-top">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={3}>
                        <Typography variant="h5" className="signatures-top-title">
                            <DriveFileRenameOutlineIcon className='icon' /> Firmas
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9} style={{ textAlign: 'right', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        {!signature && !loading && (
                            <Typography variant="body2" style={{ backgroundColor: 'yellow', padding: '5px', marginRight: '10px', borderRadius: '4px' }}>
                                Necesita crear una firma
                            </Typography>
                        )}
                        <Tooltip title="Crear Firma">
                            <IconButton onClick={handleOpenSign} aria-label="Crear Firma">
                                <DriveFileRenameOutlineIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </div>
            <div className="signatures-content">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={5}>
                        <Box sx={{ width: '100%' }}>
                            <Grid container alignItems="center">
                                <Grid item xs>
                                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="signatures tabs">
                                        <Tab label="Documentos" />
                                        <Tab label="Solicitar" />
                                    </Tabs>
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Actualizar Listados">
                                        <IconButton onClick={updateDocumentLists} aria-label="Actualizar" size="small">
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Box sx={{ padding: 2 }}>
                                {tabValue === 0 && (
                                    <Box sx={{ width: '100%' }}>
                                        <Tabs value={documentInnerTabValue} onChange={handleDocumentInnerTabChange} aria-label="document inner tabs">
                                            <Tab label={`Disponibles para firmar (${disponiblesDocuments.length})`} />
                                            <Tab label={`Firmados (${firmadosDocuments.length})`} />
                                        </Tabs>
                                        <Box sx={{ padding: 2 }}>
                                            {documentInnerTabValue === 0 && (
                                                <div className="signatures-content-section">
                                                    <Typography variant="h6" className="section-title" style={{ marginBottom: 10 }}>Disponibles para firmar</Typography>
                                                    <div className="signatures-content-section-filters" style={{ marginBottom: 20 }}>
                                                        <TextField
                                                            label="Buscar"
                                                            variant="outlined"
                                                            value={disponiblesSearchTerm}
                                                            onChange={(e) => setDisponiblesSearchTerm(e.target.value)}
                                                            size="small"
                                                            style={{ width: '80%', marginRight: '10px' }}
                                                        />
                                                    </div>
                                                    <TableContainer component={Paper} style={{ maxHeight: 300 }}>
                                                        <Table stickyHeader aria-label="disponibles documents table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {getDisplayColumns(filteredDisponiblesDocuments).map((column, index) => (
                                                                        <TableCell key={index}>{formatColumnName(column)}</TableCell>
                                                                    ))}
                                                                    <TableCell>Acciones</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {!loading ? (
                                                                    filteredDisponiblesDocuments.length > 0 ? (
                                                                        filteredDisponiblesDocuments.map((doc, index) => (
                                                                            <TableRow key={index}>
                                                                                {getDisplayColumns(filteredDisponiblesDocuments).map((column, idx) => (
                                                                                    <TableCell key={idx}>{dateColumns.includes(column) ? formatDate(doc[column]) : doc[column]}</TableCell>
                                                                                ))}
                                                                                <TableCell>
                                                                                    <Tooltip title={signature ? 'Firmar Documento' : 'Necesita crear una firma'}>
                                                                                        <span>
                                                                                            <IconButton
                                                                                                onClick={(e) => handleTogglePDF(e, doc.id_documento, doc.nombre_documento, true, doc.estado, doc.tipo_documento)}
                                                                                                disabled={!signature}
                                                                                                aria-label="Sign Document"
                                                                                            >
                                                                                                <RateReviewIcon className='action-icon' />
                                                                                            </IconButton>
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))
                                                                    ) : (
                                                                        <TableRow>
                                                                            <TableCell colSpan={getDisplayColumns(filteredDisponiblesDocuments).length + 1}>No hay documentos disponibles</TableCell>
                                                                        </TableRow>
                                                                    )
                                                                ) : (
                                                                    [0, 0, 0].map((_, index) => (
                                                                        <TableRow key={index}>
                                                                            {getDisplayColumns(filteredDisponiblesDocuments).map((_, idx) => (
                                                                                <TableCell key={idx}><Skeleton /></TableCell>
                                                                            ))}
                                                                            <TableCell><Skeleton /></TableCell>
                                                                        </TableRow>
                                                                    ))
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </div>
                                            )}
                                            {documentInnerTabValue === 1 && (
                                                <div className="signatures-content-section">
                                                    <Typography variant="h6" className="section-title" style={{ marginBottom: 10 }}>Firmados</Typography>
                                                    <div className="signatures-content-section-filters" style={{ marginBottom: 20 }}>
                                                        <TextField
                                                            label="Buscar"
                                                            variant="outlined"
                                                            value={firmadosSearchTerm}
                                                            onChange={(e) => setFirmadosSearchTerm(e.target.value)}
                                                            size="small"
                                                            style={{ width: '80%', marginRight: '10px' }}
                                                        />
                                                    </div>
                                                    <TableContainer component={Paper} style={{ maxHeight: 300 }}>
                                                        <Table stickyHeader aria-label="firmados documents table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {getDisplayColumns(filteredFirmadosDocuments).map((column, index) => (
                                                                        <TableCell key={index}>{formatColumnName(column)}</TableCell>
                                                                    ))}
                                                                    <TableCell>Acciones</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {!loading ? (
                                                                    filteredFirmadosDocuments.length > 0 ? (
                                                                        filteredFirmadosDocuments.map((doc, index) => (
                                                                            <TableRow key={index}>
                                                                                {getDisplayColumns(filteredFirmadosDocuments).map((column, idx) => (
                                                                                    <TableCell key={idx}>{dateColumns.includes(column) ? formatDate(doc[column]) : doc[column]}</TableCell>
                                                                                ))}
                                                                                <TableCell>
                                                                                    <PreviewIcon onClick={(e) => handleTogglePDF(e, doc.id_documento, doc.nombre_documento, false, doc.estado)} className='action-icon' />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))
                                                                    ) : (
                                                                        <TableRow>
                                                                            <TableCell colSpan={getDisplayColumns(filteredFirmadosDocuments).length + 1}>No hay documentos firmados</TableCell>
                                                                        </TableRow>
                                                                    )
                                                                ) : (
                                                                    [0, 0, 0].map((_, index) => (
                                                                        <TableRow key={index}>
                                                                            {getDisplayColumns(filteredFirmadosDocuments).map((_, idx) => (
                                                                                <TableCell key={idx}><Skeleton /></TableCell>
                                                                            ))}
                                                                            <TableCell><Skeleton /></TableCell>
                                                                        </TableRow>
                                                                    ))
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </div>
                                            )}
                                        </Box>
                                    </Box>
                                )}

                                {tabValue === 1 && (
                                    <Box sx={{ width: '100%' }}>
                                        <Tabs value={innerTabValue} onChange={handleInnerTabChange} aria-label="inner tabs">
                                            <Tab label="Nuevo Documento" />
                                            <Tab label={`En espera (${enEsperaDocuments.length})`} />
                                            <Tab label={`Finalizados (${finalizadosDocuments.length})`} />
                                            <Tab label={`Cerrados (${cerradosDocuments.length})`} />
                                        </Tabs>
                                        <Box sx={{ padding: 2 }}>
                                            {innerTabValue === 0 && (
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <div className="propios-section">
                                                            <Typography variant="h6" className="section-title">Subir Documento para Firmar</Typography>
                                                            <section className="container-drop">
                                                                <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
                                                                    <input {...getInputProps()} />
                                                                    {
                                                                        isDragActive ?
                                                                            <p>Suelta el archivo aquí...</p> :
                                                                            <p>Arrastra y suelta un archivo PDF aquí, o haz clic para seleccionar uno</p>
                                                                    }
                                                                </div>
                                                            </section>
                                                            <TextField
                                                                label="Nombre del Documento"
                                                                variant="outlined"
                                                                value={documentName}
                                                                onChange={(e) => setDocumentName(e.target.value)}
                                                                fullWidth
                                                                style={{ marginTop: '5px' }}
                                                                size="small"
                                                            />
                                                            {selectedFile && (
                                                                <div className="selected-file">
                                                                    <p>Archivo seleccionado: {selectedFile.name}</p>
                                                                </div>
                                                            )}
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={notifyUsers}
                                                                        onChange={(e) => setNotifyUsers(e.target.checked)}
                                                                        name="notifyUsers"
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label="Notificar a los usuarios asignados mediante correo electrónico"
                                                            />
                                                            <FormControl fullWidth variant="outlined" size="small" style={{ marginTop: '10px' }}>
                                                                <InputLabel id="document-type-label">Tipo de Documento</InputLabel>
                                                                <Select
                                                                    labelId="document-type-label"
                                                                    id="document-type-select"
                                                                    value={documentType.value}
                                                                    onChange={(e) => setDocumentType(e.target)}
                                                                    label="Tipo de Documento"
                                                                >
                                                                    {documentTypes.map((type) => (
                                                                        <MenuItem key={type.value} value={type.value}>
                                                                            {type.label}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <div className="search-user-section">
                                                            <Typography variant="h6" className="section-title" style={{ marginBottom: 20 }}>Buscar Usuarios</Typography>
                                                            <Autocomplete
                                                                options={userOptions}
                                                                getOptionLabel={(option) => (option.nombre + " " + option.apellido) || ''}
                                                                inputValue={userSearch}
                                                                onInputChange={(event, newInputValue) => {
                                                                    setUserSearch(newInputValue);
                                                                }}
                                                                onChange={(event, newValue) => {
                                                                    if (newValue && !selectedUsers.some(user => user.id_user === newValue.id_user)) {
                                                                        setSelectedUsers([...selectedUsers, newValue]);
                                                                    }
                                                                    setUserSearch('');
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Buscar usuarios"
                                                                        variant="outlined"
                                                                        size="small"
                                                                    />
                                                                )}
                                                            />
                                                            <div className="selected-users" style={{ marginBottom: 10, marginTop: 10 }}>
                                                                {selectedUsers.map((user) => (
                                                                    <div key={user.id_user} className="selected-user">
                                                                        <span>{user.nombre} {user.apellido}</span>
                                                                        <IconButton size="small" onClick={() => handleRemoveUser(user.id_user)}>
                                                                            <CloseIcon fontSize="small" />
                                                                        </IconButton>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={handleFileUpload}
                                                            disabled={!selectedFile || !documentName || selectedUsers.length === 0 || !documentType}
                                                        >
                                                            Enviar Documento
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {innerTabValue === 1 && (
                                                <div>
                                                    <Typography variant="h6" className="section-title" style={{ marginBottom: 10 }}>En espera</Typography>
                                                    <div className="signatures-content-section-filters" style={{ marginBottom: 20 }}>
                                                        <TextField
                                                            label="Buscar"
                                                            variant="outlined"
                                                            value={enEsperaSearchTerm}
                                                            onChange={(e) => setEnEsperaSearchTerm(e.target.value)}
                                                            size="small"
                                                            style={{ width: '80%', marginRight: '10px' }}
                                                        />
                                                    </div>
                                                    <TableContainer component={Paper} style={{ maxHeight: 300 }}>
                                                        <Table stickyHeader aria-label="en espera documents table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {getDisplayColumns(filteredEnEsperaDocuments).map((column, index) => (
                                                                        <TableCell key={index}>{formatColumnName(column)}</TableCell>
                                                                    ))}
                                                                    <TableCell>Acciones</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {!loading ? (
                                                                    filteredEnEsperaDocuments.length > 0 ? (
                                                                        filteredEnEsperaDocuments.map((doc, index) => (
                                                                            <TableRow key={index}>
                                                                                {getDisplayColumns(filteredEnEsperaDocuments).map((column, idx) => (
                                                                                    <TableCell key={idx}>{dateColumns.includes(column) ? formatDate(doc[column]) : doc[column]}</TableCell>
                                                                                ))}
                                                                                <TableCell>
                                                                                    <Tooltip title="Ver usuarios pendientes">
                                                                                        <IconButton onClick={() => {
                                                                                            setSelectedUsersDoc(doc.usuarios);
                                                                                            setOpenUsersModal(true);
                                                                                        }}>
                                                                                            <GroupIcon className='action-icon' />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                    <Tooltip title="Ver documento">
                                                                                        <IconButton onClick={(e) => handleTogglePDF(e, doc.id_documento, doc.nombre_documento, false, doc.estado)}>
                                                                                            <PreviewIcon className='action-icon' />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))
                                                                    ) : (
                                                                        <TableRow>
                                                                            <TableCell colSpan={getDisplayColumns(filteredEnEsperaDocuments).length + 1}>No hay documentos en espera</TableCell>
                                                                        </TableRow>
                                                                    )
                                                                ) : (
                                                                    [0, 0, 0].map((_, index) => (
                                                                        <TableRow key={index}>
                                                                            {getDisplayColumns(filteredEnEsperaDocuments).map((_, idx) => (
                                                                                <TableCell key={idx}><Skeleton /></TableCell>
                                                                            ))}
                                                                            <TableCell><Skeleton /></TableCell>
                                                                        </TableRow>
                                                                    ))
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </div>
                                            )}
                                            {innerTabValue === 2 && (
                                                <div>
                                                    <Typography variant="h6" className="section-title" style={{ marginBottom: 10 }}>Finalizados</Typography>
                                                    <div className="signatures-content-section-filters" style={{ marginBottom: 20 }}>
                                                        <TextField
                                                            label="Buscar"
                                                            variant="outlined"
                                                            value={finalizadosSearchTerm}
                                                            onChange={(e) => setFinalizadosSearchTerm(e.target.value)}
                                                            size="small"
                                                            style={{ width: '80%', marginRight: '10px' }}
                                                        />
                                                    </div>
                                                    <TableContainer component={Paper} style={{ maxHeight: 300 }}>
                                                        <Table stickyHeader aria-label="finalizados documents table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {getDisplayColumns(filteredFinalizadosDocuments).map((column, index) => (
                                                                        <TableCell key={index}>{formatColumnName(column)}</TableCell>
                                                                    ))}
                                                                    <TableCell>Acciones</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {!loading ? (
                                                                    filteredFinalizadosDocuments.length > 0 ? (
                                                                        filteredFinalizadosDocuments.map((doc, index) => (
                                                                            <TableRow key={index}>
                                                                                {getDisplayColumns(filteredFinalizadosDocuments).map((column, idx) => (
                                                                                    <TableCell key={idx}>{dateColumns.includes(column) ? formatDate(doc[column]) : doc[column]}</TableCell>
                                                                                ))}
                                                                                <TableCell>
                                                                                    <PreviewIcon onClick={(e) => obtenerDocCerrado(e, doc.id_documento, doc.nombre_documento, false, doc.estado)} className='action-icon' />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))
                                                                    ) : (
                                                                        <TableRow>
                                                                            <TableCell colSpan={getDisplayColumns(filteredFinalizadosDocuments).length + 1}>No hay documentos finalizados</TableCell>
                                                                        </TableRow>
                                                                    )
                                                                ) : (
                                                                    [0, 0, 0].map((_, index) => (
                                                                        <TableRow key={index}>
                                                                            {getDisplayColumns(filteredFinalizadosDocuments).map((_, idx) => (
                                                                                <TableCell key={idx}><Skeleton /></TableCell>
                                                                            ))}
                                                                            <TableCell><Skeleton /></TableCell>
                                                                        </TableRow>
                                                                    ))
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </div>
                                            )}
                                            {innerTabValue === 3 && (
                                                <div>
                                                    <Typography variant="h6" className="section-title" style={{ marginBottom: 10 }}>Cerrados</Typography>
                                                    <div className="signatures-content-section-filters" style={{ marginBottom: 20 }}>
                                                        <TextField
                                                            label="Buscar"
                                                            variant="outlined"
                                                            value={cerradosSearchTerm}
                                                            onChange={(e) => setCerradosSearchTerm(e.target.value)}
                                                            size="small"
                                                            style={{ width: '80%', marginRight: '10px' }}
                                                        />
                                                    </div>
                                                    <TableContainer component={Paper} style={{ maxHeight: 300 }}>
                                                        <Table stickyHeader aria-label="cerrados documents table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {getDisplayColumns(filteredCerradosDocuments).map((column, index) => (
                                                                        <TableCell key={index}>{formatColumnName(column)}</TableCell>
                                                                    ))}
                                                                    <TableCell>Acciones</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {!loading ? (
                                                                    filteredCerradosDocuments.length > 0 ? (
                                                                        filteredCerradosDocuments.map((doc, index) => (
                                                                            <TableRow key={index}>
                                                                                {getDisplayColumns(filteredCerradosDocuments).map((column, idx) => (
                                                                                    <TableCell key={idx}>{dateColumns.includes(column) ? formatDate(doc[column]) : doc[column]}</TableCell>
                                                                                ))}
                                                                                <TableCell>
                                                                                    <PreviewIcon onClick={(e) => handleTogglePDF(e, doc.id_documento, doc.nombre_documento, false, doc.estado)} className='action-icon' />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))
                                                                    ) : (
                                                                        <TableRow>
                                                                            <TableCell colSpan={getDisplayColumns(filteredCerradosDocuments).length + 1}>No hay documentos cerrados</TableCell>
                                                                        </TableRow>
                                                                    )
                                                                ) : (
                                                                    [0, 0, 0].map((_, index) => (
                                                                        <TableRow key={index}>
                                                                            {getDisplayColumns(filteredCerradosDocuments).map((_, idx) => (
                                                                                <TableCell key={idx}><Skeleton /></TableCell>
                                                                            ))}
                                                                            <TableCell><Skeleton /></TableCell>
                                                                        </TableRow>
                                                                    ))
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </div>
                                            )}
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={7}>
                        {openPDF && (
                            !loadingPDF ? (
                                <div className="signatures-content-section">
                                    <Typography variant="h6" className='signatures-content-section-docTitle'>
                                        {docName}
                                    </Typography>
                                    <PdfViewer
                                        pdf={pdf}
                                        signature={signature}
                                        idDocumento={docId}
                                        docName={docName}
                                        email={profileState.email}
                                        width={900}
                                        canEdit={canEdit}
                                        onDocumentUpdated={updateDocumentLists}
                                        onClosePDF={handleClosePDF}
                                        elementsFromEndpoint={pdfElements}
                                        canCloseDocument={canCloseDocument}
                                        estadoDoc={estado}
                                        documentType={documentTypePDF}
                                    />
                                </div>
                            ) : (
                                <div className="signatures-content-section skeleton">
                                    <Skeleton height={'100%'} width={'100%'} />
                                </div>
                            )
                        )}
                    </Grid>
                </Grid>
            </div>

            <UploadSignature
                open={openSign}
                handleClose={handleCloseSign}
                onSignatureSave={(newSignature) => setSignature(newSignature)}
            />

            {/* Alerta Modal */}
            <Dialog open={openAlertModal} onClose={() => setOpenAlertModal(false)}>
                <DialogTitle style={{ display: 'flex', alignItems: 'center' }}>
                    <InfoIcon color="primary" style={{ marginRight: 8 }} />
                    Documentos Disponibles para Firmar
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ backgroundColor: '#f0f0f0', padding: 2, borderRadius: 2 }}>
                        <Typography variant="body1">
                            Hay {disponiblesDocuments.length} documento{disponiblesDocuments.length !== 1 ? 's' : ''} disponible{disponiblesDocuments.length !== 1 ? 's' : ''} para firmar.
                        </Typography>
                        <List>
                            {disponiblesDocuments.slice(0, 3).map((doc, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={doc.nombre_documento} />
                                </ListItem>
                            ))}
                            {disponiblesDocuments.length > 3 && (
                                <ListItem>
                                    <ListItemText primary={`... y ${disponiblesDocuments.length - 3} más`} />
                                </ListItem>
                            )}
                        </List>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenAlertModal(false)} color="primary">
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Modal for displaying pending users */}
            <Dialog open={openUsersModal} onClose={() => setOpenUsersModal(false)}>
                <DialogTitle>Usuarios pendientes por firmar</DialogTitle>
                <DialogContent>
                    {selectedUsersDoc && selectedUsersDoc.length > 0 ? (
                        <List>
                            {selectedUsersDoc.map((user, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={`${user}`} />
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <Typography>No hay usuarios pendientes</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenUsersModal(false)} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Signatures;
