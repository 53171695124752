import React , {useState,createContext,useEffect, useContext} from 'react';

export const OltSearch = createContext();

export const OltSearchProvider = props =>{
    const [currentOlt, setCurrentOlt] = useState('none')
    

    const value = [currentOlt, setCurrentOlt]
    return (
      <OltSearch.Provider value={value}>
        {props.children}
      </OltSearch.Provider>
    )
  }
