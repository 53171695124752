import React , {useState,createContext,useEffect, useContext} from 'react';

export const DashboardType = createContext();

export const DashProvider = props =>{
    const [dashType,setDashType] = useState('home');
    
  
    const value = [dashType,setDashType]
    return (
      <DashboardType.Provider value={value}>
        {props.children}
      </DashboardType.Provider>
    )
  }
