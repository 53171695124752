import './App.css';
import Routes_ from './routes';
import {LoggedProvider} from './context/LoggedContext'
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'


function App() {
  return (
    <div>
      <ReactNotifications />
      <LoggedProvider>
        <Routes_ />
      </LoggedProvider>
    </div>
    
  );
}

export default App;
