import React, { useState, useContext, useRef } from 'react';
import login from './login.scss'
import TextField from '@mui/material/TextField';
import mainLogo from '../../../styles/img/logo_final.png';
import { Link, useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';
import Send from '@mui/icons-material/Send';
import axios from 'axios';
import { LoggedContext } from '../../../context/LoggedContext';
import { UserContext } from '../../../context/UserContext';
import { CurrentPage } from '../../../context/PageContext';
import { Enviroments } from '../../../context/Enviroments';
import { Store } from 'react-notifications-component';



const Login = () => {
    const [enviroment] = useContext(Enviroments);
    const [LoggedIn, setLoggedIn] = useContext(LoggedContext);
    const [currentPage, setCurrentPage] = React.useContext(CurrentPage);
    const [profileState, setProfileState] = useContext(UserContext);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [values, setValues] = React.useState({
        email: '',
        password: '',
        showPassword: false,

    });
    

    async function login(username, password) {
        setLoading(true);
        let token = localStorage.getItem("token");
        const headers = {
            "Accept": "application/json",
            'Authorization': 'Bearer token',
            "Access-Control-Allow-Origin": '*'
        };

        const data_login = 
        {
            "User": username,
            "Password": password
        }

        await axios.post(enviroment+'/login-user/', data_login)
             .then(response => {
                
                if (response.data == 0){
                    alert_wrongUser()
                    setLoggedIn(false)
                    setLoading(false)
                }else if(response.data == 1){
                    alert_wrongPassword()
                    setLoggedIn(false)
                    setLoading(false)
                }else{
                    localStorage.setItem("token", response.data[1])
                    setProfileState({
                        username: response.data[0].RowKey,
                        email: response.data[0].Email,
                        rol: response.data[0].Rol,
                        userId: response.data[0].Id,
                        nombre: response.data[0].Nombre,
                        apellido: response.data[0].Apellido
                    })

                    history.push("/home")
                    setCurrentPage("Lobby");
                    setLoggedIn(true)
                    setLoading(false)
                }
                 
                 /* fetchData(); */
 
             }).catch(error => {
                 console.log(error)
                 alert_wrongLogin()
                 setLoggedIn(false)
                 setLoading(false)
             }) 
    }

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const alert_emptyEmail = () => {
        Store.addNotification({
            title: "Debes ingresar un usuario o correo",
            message: " ",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true
            }
        });
    }
    const alert_validateEmail = () => {
        Store.addNotification({
            title: "El formato de correo no es válido",
            message: " ",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true
            }
        });
    }
    const alert_emptyPassword = () => {
        Store.addNotification({
            title: "Debes ingresar una contraseña.",
            message: " ",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true
            }
        });
    }

    const alert_wrongLogin = () => {
        Store.addNotification({
            title: "Ha ocurrido un error en el inicio de sesión.",
            message: " ",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true
            }
        });
    }

    const alert_wrongUser = () => {
        Store.addNotification({
            title: "Usuario no registrado en la plataforma.",
            message: " ",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true
            }
        });
    }

    const alert_wrongPassword = () => {
        Store.addNotification({
            title: "Contraseña incorrecta.",
            message: " ",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true
            }
        });
    }
    const reg_exp = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/
    const onClick = e => {
        e.preventDefault();
        if (values.email === "") {
            alert_emptyEmail()
        }
        /*else if(!reg_exp.exec(values.email)){
            alert_validateEmail();
        }*/
        else if (values.password === "") {
            alert_emptyPassword()
        } else {
            login(values.email, values.password)
        }

    }


    return (
        <div className="login">
            <div className="waveWrapper waveAnimation">
                <div className="waveWrapperInner bgTop">
                    <div className="wave waveTop" style={{ backgroundImage: "url('http://front-end-noobs.com/jecko/img/wave-top.png')" }}></div>
                </div>
                <div className="waveWrapperInner bgMiddle">
                    <div className="wave waveMiddle" style={{ backgroundImage: "url('http://front-end-noobs.com/jecko/img/wave-mid.png')" }}></div>
                </div>
                <div className="waveWrapperInner bgBottom">
                    <div className="wave waveBottom" style={{ backgroundImage: "url('http://front-end-noobs.com/jecko/img/wave-bot.png')" }}></div>
                </div>
            </div>
            <div className="card">
                <img className="logo" src={mainLogo} />
                <form className="form">
                    <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-email">Usuario o Correo</InputLabel>
                        <OutlinedInput
                            className="form-group-text-field"
                            id="outlined-adornment-email"
                            type='text'
                            value={values.email}
                            onChange={handleChange('email')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <PersonIcon />
                                </InputAdornment>
                            }
                            label="Usuario o Correo"
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                        <OutlinedInput
                            className="form-group-text-field"
                            id="outlined-adornment-password"
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleChange('password')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>

                </form>
           {/*      <div className='links'>
                    <Link to="/activate" >Activar cuenta</Link>
                    <Link to="/forgot" >Olvidé mi contraseña</Link>
                </div> */}

                <Button onClick={onClick} onKeyPress={onClick} to="/ftth/home" className="login-button" variant="contained" endIcon={<Send />}>
                    Entrar
                </Button>



            </div>

            {/*<div>
                <svg className="waves" throwIfNamespace='false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                    <defs>
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g className="parallax">
                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                    </g>
                </svg>
            </div>
            */}
        </div>
    )
}

export default Login;