import React , {useState,createContext,useEffect, useContext} from 'react';

export const ChangeTput = createContext();

export const ChangeTputProvider = props =>{
    const [changeTput ,setChangeTput] = useState(false);
    

    const value = [changeTput, setChangeTput]
    return (
      <ChangeTput.Provider value={value}>
        {props.children}
      </ChangeTput.Provider>
    )
  }
