import React , {useState,createContext} from 'react';

export const LoggedContext = createContext();

export const LoggedProvider = props =>{
    const [loggedIn,setLoggedIn] = useState(localStorage.getItem("token") ? true : false);
    const value = [loggedIn, setLoggedIn]

    return (
        <LoggedContext.Provider value={value}>
            {props.children}
        </LoggedContext.Provider>

    )

}