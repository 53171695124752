import React , {useState,createContext,useEffect, useContext} from 'react';

export const UpdateDiagramLabels = createContext();

export const UpdateDiagramLabelsProvider = props =>{
    const [updateDiagramLabels,setUpdateDiagramLabels] = useState(false);
    
    const value = [updateDiagramLabels,setUpdateDiagramLabels]
    return (
      <UpdateDiagramLabels.Provider value={value}>
        {props.children}
      </UpdateDiagramLabels.Provider>
    )
  }
