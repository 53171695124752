import React , {useState,createContext,useEffect, useContext} from 'react';

export const TbuWeek = createContext();

export const TbuWeekProvider = props =>{
    const [currentTbuWeek, setCurrentTbuWeek] = useState('none')
    

    const value = [currentTbuWeek, setCurrentTbuWeek]
    return (
      <TbuWeek.Provider value={value}>
        {props.children}
      </TbuWeek.Provider>
    )
  }
