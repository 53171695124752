// routesConfig.js
import Lobby from './lobby/lobby';
import Profile from '../shared/views/profile/profile';
import Signatures from '../shared/views/signatures/signatures';
import GestionAcceso from './gestion_acceso/gestion_acceso';
import Test from './test/test';

export const routesConfig = [
  { path: '/home', Component: Lobby },
  { path: '/profile', Component: Profile },
  { path: '/test', Component: Test },
  { path: '/signatures', Component: Signatures },
  { path: '/gestion-acceso', Component: GestionAcceso },

];
