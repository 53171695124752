import React, { useState, createContext, useEffect, useContext } from 'react';
import axios from 'axios';
import { LoggedContext } from './LoggedContext';
import { Enviroments } from './Enviroments';
import { store } from 'react-notifications-component';

export const UserContext = createContext();

export const UserProvider = props => {
  const [enviroment] = useContext(Enviroments);
  const [LoggedIn, setLoggedIn] = useContext(LoggedContext)
  const [profileState, setProfileState] = useState({
    username: null,
    email: null,
    rol: "viewer",
    userId: null
  });

  async function fetchData() {

    let token = localStorage.getItem("token")
    const headers = {
      "Accept": "application/json",
      'Authorization': `Bearer ${token}`,
    };

    axios(enviroment+"/get-user-by-token/", { headers })
      .then(response => {
        
        if (response.data == 0){
          setLoggedIn(false)
        }else{
            setProfileState({
              username: response.data.RowKey,
              email: response.data.Email,
              rol: response.data.Rol,
              userId: response.data.Id,
              nombre: response.data.Nombre,
              apellido: response.data.Apellido
            })
            setLoggedIn(true)
        }

      }).catch(error => {
        setLoggedIn(false)
        console.log("cambió")
      }) 
  }


  if (LoggedIn) {
    if (profileState.username == null) {
       fetchData(); 
    } else {
    }

  }
  const value = [profileState, setProfileState]
  return (
    <UserContext.Provider value={value}>
      {props.children}
    </UserContext.Provider>
  )
}
