import React, { useState, useEffect, useContext, useRef } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Send from '@mui/icons-material/Send';
import CanvasDraw from 'react-canvas-draw';
import IconButton from '@mui/material/IconButton';
import LoopIcon from '@mui/icons-material/Loop';
import { useDropzone } from 'react-dropzone';
import { Store } from 'react-notifications-component';
import { Enviroments } from '../../../../context/Enviroments';
import { UserContext } from '../../../../context/UserContext';
import { SignaturesContext } from '../../../../context/SignaturesContext';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import './uploadSignature.scss';

function UploadSignature({ open, handleClose, onSignatureSave }) {
  const [enviroment] = useContext(Enviroments);
  const [signature, setSignature]= useContext(SignaturesContext)
  const [profileState, setProfileState] = useContext(UserContext);
  const canvasRef = useRef(null);

  const [valueTab, setValueTab] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
    setSignatureFile(null);
    setFiles([]);
    if (canvasRef.current) {
      canvasRef.current.clear();
    }
    setCanvasIsEmpty(true);
  };

  const [signatureFile, setSignatureFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [canvasIsEmpty, setCanvasIsEmpty] = useState(true);

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: '.png',
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      setSignatureFile(file);
      setFiles(acceptedFiles.map(file => (
        <li key={file.path}>
          {file.path} - {file.size} bytes
        </li>
      )));
    },
  });

  const alert_signsucess = () => {
    Store.addNotification({
      title: 'La firma se ha subido correctamente.',
      message: ' ',
      type: 'success',
      insert: 'bottom',
      container: 'bottom-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  };

  const alert_signwrong = () => {
    Store.addNotification({
      title: 'Algo salió mal.',
      message: ' ',
      type: 'danger',
      insert: 'bottom',
      container: 'bottom-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  };

  async function encodeFileAsBase64URL(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('loadend', () => {
        resolve(reader.result);
      });
      reader.readAsDataURL(file);
    });
  }

  async function uploadSignatureUpload() {
    if (!signatureFile) return;
    const token = localStorage.getItem('token');
    const headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
    };
    const base64URL = await encodeFileAsBase64URL(signatureFile);
    //REVISAR ERROR ENVIO BODY
    const body = {
      signature: base64URL,
      name: profileState.username,
      id_usuario: profileState.userId
    };
    await axios
      .post(enviroment + '/upload-signature/', body, { headers })
      .then((response) => {
        setSignature(base64URL);
        alert_signsucess();
        // Update profileState
        setProfileState(prevState => ({
          ...prevState,
          signature: base64URL,
        }));
        onSignatureSave(base64URL);
        handleClose();
      })
      .catch((error) => {
        alert_signwrong();
      });
  }

  async function uploadSignatureDraw() {
    if (!canvasRef.current) return;
    const signatureDataURL = canvasRef.current.getDataURL();
    const token = localStorage.getItem('token');
    const headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
    };
    const body = {
      signature: signatureDataURL,
      name: profileState.username,
      id_usuario: profileState.userId
    };
    await axios
      .post(enviroment + '/upload-signature/', body, { headers })
      .then((response) => {
        setSignature(signatureDataURL);
        alert_signsucess();
        onSignatureSave(signatureDataURL);
        handleClose();
      })
      .catch((error) => {
        alert_signwrong();
      });
  }

 

  useEffect(() => {
    if (open) {
      setSignatureFile(null);
      setFiles([]);
      if (canvasRef.current) {
        canvasRef.current.clear();
      }
      setCanvasIsEmpty(true);
    }
  }, [open, profileState.username]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '25px',
    boxShadow: 24,
    paddingBottom: 4,
  };

  const clearCanvas = () => {
    if (canvasRef.current) {
      canvasRef.current.clear();
      setCanvasIsEmpty(true);
    }
  };

  const handleCanvasChange = () => {
    if (canvasRef.current) {
      const saveData = canvasRef.current.getSaveData();
      const data = JSON.parse(saveData);
      setCanvasIsEmpty(data.lines.length === 0);
    }
  };

  const isSaveDisabled = () => {
    if (valueTab === 0) {
      return !signatureFile;
    } else if (valueTab === 1) {
      return canvasIsEmpty;
    }
    return true;
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <div className="modal-title">
          <span>Editar Firma</span>
        </div>
        <span>Firma Actual</span>
        {signature ? (
          <img src={signature} height="50px" alt="Firma Actual" />
        ) : (
          <p>No hay firma disponible</p>
        )}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={valueTab} onChange={handleChangeTab} aria-label="signature tabs">
            <Tab label="Subir" {...a11yProps(0)} />
            <Tab label="Dibujar" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={valueTab} index={0}>
          <section className="container-drop">
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <p>Arrastra y suelta un archivo PNG aquí, o haz clic para seleccionar uno</p>
            </div>
            <aside>
              <h4>Archivo</h4>
              <ul>{files}</ul>
            </aside>
          </section>
          <div className="modal-buttons-footer">
            <Button
              onClick={uploadSignatureUpload}
              className="change-button"
              variant="contained"
              endIcon={<Send />}
              disabled={isSaveDisabled()}
            >
              Guardar
            </Button>
          </div>
        </TabPanel>
        <TabPanel value={valueTab} index={1}>
          <CanvasDraw
            ref={canvasRef}
            canvasWidth={300}
            canvasHeight={200}
            className="modal-signature"
            brushRadius={1}
            lazyRadius={0}
            onChange={handleCanvasChange}
            style={{
              boxShadow: '0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3)',
            }}
          />
          <div className="modal-buttons-footer">
            <IconButton onClick={clearCanvas} className="reset-button" variant="contained">
              <LoopIcon />
            </IconButton>
            <Button
              onClick={uploadSignatureDraw}
              className="change-button"
              variant="contained"
              endIcon={<Send />}
              disabled={isSaveDisabled()}
            >
              Guardar
            </Button>
          </div>
        </TabPanel>
      </Box>
    </Modal>
  );
}

UploadSignature.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSignatureSave: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`signature-tabpanel-${index}`} aria-labelledby={`signature-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `signature-tab-${index}`,
    'aria-controls': `signature-tabpanel-${index}`,
  };
}

export default UploadSignature;
