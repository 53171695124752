import React , {useState,createContext,useEffect, useContext} from 'react';

export const SdmhWeek = createContext();

export const SdmhWeekProvider = props =>{
    const [currentSdmhWeek, setCurrentSdmhWeek] = useState(['none','ds'])
    

    const value = [currentSdmhWeek, setCurrentSdmhWeek]
    return (
      <SdmhWeek.Provider value={value}>
        {props.children}
      </SdmhWeek.Provider>
    )
  }
