import React , {useState,createContext,useEffect, useContext} from 'react';

export const WeekSimulator = createContext();

export const WeekSimulatorProvider = props =>{
    const [weekSelected, setWeekSelected] = useState(null)
    

    const value = [weekSelected, setWeekSelected]
    return (
      <WeekSimulator.Provider value={value}>
        {props.children}
      </WeekSimulator.Provider>
    )
  }
