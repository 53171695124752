import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { routesConfig } from './routesConfig'; // Asegúrate de que la ruta es correcta
import Navegation from './../shared/components/navegation/navegation';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DashboardType } from '../../context/dashContext';
import { CurrentPage } from '../../context/PageContext';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const Inside = () => {
    const [dashType, setDashType] = React.useContext(DashboardType);
    const [currentPage, setCurrentPage] = React.useContext(CurrentPage);
    const url = window.location.href.split('/');
    const dash = url[4];
  
    React.useEffect(() => {
      if (
        currentPage !== 'Administration' &&
        currentPage !== 'Profile' &&
        currentPage !== 'Tickets' &&
        currentPage !== 'Signatures' &&
        currentPage !== 'Simulator' &&
        currentPage !== 'Gestion Acceso' && 
        currentPage !== 'Lobby' 
      ) {
        setDashType(dash);
      }
      if (
        dash !== 'ftth' &&
        dash !== 'hfc' &&
        dash !== 'home' &&
        dash !== 'lobby1' &&
        dash !== 'lobby2' &&
        dash !== 'lobby3' &&
        dash !== 'core' &&
        dash !== 'simulator' &&
        dash !== 'test' &&
        dash !== 'incidencias' &&
        dash !== 'cmts' &&
        dash != "gestion-acceso"
      ) {
        setDashType('tools');
      }
    }, [dash]);
  
    return (
      <div
        className="inside"
        style={dashType === 'hfc' || dashType === 'ftth' ? { paddingLeft: '4.5rem' } : {}}
      >
        <Navegation />
        <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
          {dashType !== 'home' &&
          dashType !== 'lobby1' &&
          dashType !== 'lobby2' &&
          dashType !== 'lobby3' ? (
            <DrawerHeader />
          ) : (
            <div></div>
          )}
          <Switch>
            {routesConfig.map(({ path, Component }) => (
              <Route key={path} path={path}>
                <Component />
              </Route>
            ))}
            {/* Agrega rutas adicionales si es necesario */}
          </Switch>
        </Box>
      </div>
    );
  };
  
  export default withRouter(Inside);