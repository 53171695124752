// Navegation.jsx

import React, { useContext, useState } from 'react';
import { Box, CssBaseline } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Header from './header';
import SideMenu from './sideMenu';
import { DashboardType } from '../../../../context/dashContext';
import { CurrentPage } from '../../../../context/PageContext';
import { UserContext } from '../../../../context/UserContext';

const Navegation = () => {
  const [currentPage, setCurrentPage] = useContext(CurrentPage);
  const [profileState] = useContext(UserContext);
  const [dashType, setDashType] = useContext(DashboardType);
  const history = useHistory();

  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  const toggleSideMenu = () => {
    setIsSideMenuOpen(!isSideMenuOpen);
  };

  const backToHome = () => {
    setDashType('home');
    history.push('/home');
    setCurrentPage('Lobby');
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header
        dashType={dashType}
        backToHome={backToHome}
        toggleSideMenu={toggleSideMenu}
        isSideMenuOpen={isSideMenuOpen}
      />
      {(dashType === 'ftth' || dashType === 'hfc') && (
        <SideMenu
          dashType={dashType}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          history={history}
          profileState={profileState}
          isSideMenuOpen={isSideMenuOpen}
        />
      )}
    </Box>
  );
};

export default Navegation;
