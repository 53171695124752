import React , {useState,createContext,useEffect, useContext} from 'react';

export const Enviroments2 = createContext();

export const Enviroments2Provider = props => {
    
    const [enviroment2, setEnviroment] = useState(window.location.href.split('/')[2].split('.')[0].split(':')[0] === 'localhost' ? 'http://172.17.69.17:9001' 
    : window.location.href.split('/')[2].split('.')[0] ===  '10' ? 'http://10.43.69.17:9001' : 'http://172.17.69.17:9001');
    
    const url = window.location.href.split('/');
    const [currentOlt, setCurrentOlt] = useState('none')

    const value = [enviroment2,  currentOlt]
    return (
      <Enviroments2.Provider value={value}>
        {props.children}
      </Enviroments2.Provider>
    )
  }
