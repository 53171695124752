import React , {useState,createContext,useEffect, useContext} from 'react';

export const CurrentPage = createContext();

export const CurrentPageProvider = props =>{
    const [currentPage, setCurrentPage] = useState('none')
    

    const value = [currentPage, setCurrentPage]
    return (
      <CurrentPage.Provider value={value}>
        {props.children}
      </CurrentPage.Provider>
    )
  }
