import React, { useState, useContext, useEffect, useRef } from 'react';
import { PDFDocument, rgb, StandardFonts, degrees } from 'pdf-lib';

import { Document, Page, pdfjs } from 'react-pdf';
import axios from 'axios';
import { Enviroments } from '../../../../../context/Enviroments';
import './pdfViewer.scss';
import { UserContext } from '../../../../../context/UserContext';
import { SignaturesContext } from '../../../../../context/SignaturesContext';
import { IconButton, Tooltip, TextField, Button, Backdrop, CircularProgress } from '@mui/material';
import { Store } from 'react-notifications-component';


// Importamos los iconos necesarios
import EditIcon from '@mui/icons-material/Edit';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LoopIcon from '@mui/icons-material/Loop';
import LockIcon from '@mui/icons-material/Lock';
import DownloadIcon from '@mui/icons-material/Download';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

// Configuración del worker de react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = (props) => {
  const { canCloseDocument, estadoDoc, documentType } = props;
  const [enviroment] = useContext(Enviroments);
  const [profileState] = useContext(UserContext);
  const [signature, setSignature] = useContext(SignaturesContext);
  const [documentView, setDocumentView] = useState(props.pdf);
  const [docName] = useState(props.docName);
  const [email] = useState(props.email);
  const [signed, setSigned] = useState(false);
  const [bytes, setBytes] = useState(null);
  const [accepted, setAccepted] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [elements, setElements] = useState([]);
  const [selectedTool, setSelectedTool] = useState('');
  const [initialPdfBytes, setInitialPdfBytes] = useState(null);
  const pdfWrapperRef = useRef(null);
  const [pageDimensions, setPageDimensions] = useState({ width: 0, height: 0 });

  const [showConfirmTooltip, setShowConfirmTooltip] = useState(false);

  const [savedElements, setSavedElements] = useState([]);

  const [textInputValue, setTextInputValue] = useState('');
  const [textInputPosition, setTextInputPosition] = useState({ x: 0, y: 0 });
  const [showTextInput, setShowTextInput] = useState(false);

  const [idDocumento] = useState(props.idDocumento);

  const [estado, setEstado] = useState('');
  // Añadir estado loading
  const [loading, setLoading] = useState(false);

  const { onDocumentUpdated, onClosePDF, elementsFromEndpoint } = props;

  useEffect(() => {
    async function loadPdfBytes() {
      const bytes = await fetch(props.pdf).then(res => res.arrayBuffer());
      setInitialPdfBytes(bytes);
      setDocumentView(props.pdf);


    }
    loadPdfBytes();
  }, [props.pdf]);

  useEffect(() => {
    const canvas = pdfWrapperRef.current.querySelector('.react-pdf__Page__canvas');
    if (canvas) {
      setPageDimensions({ width: canvas.width, height: canvas.height });
    }
  }, [pageNumber, numPages]);

  useEffect(() => {
    if (estadoDoc !== 'CERRADO' && elementsFromEndpoint && elementsFromEndpoint.length > 0) {
      const mappedElements = elementsFromEndpoint.map(el => ({
        type: el.tipo,
        x: el.coordenada_x,
        y: el.coordenada_y,
        text: el.texto,
        pageNumber: el.pagina,
      }));
      setElements(mappedElements);
    } else {
      setElements([]);
    }
  }, [elementsFromEndpoint, estadoDoc]);

  const base64toPDF = (data) => {
    return new Blob([data], { type: 'application/pdf' });
  };

  function previsualizar(pdfBytes) {
    const file = base64toPDF(pdfBytes);
    const fileURL = URL.createObjectURL(file);
    setDocumentView(fileURL);
  }

  function handleClick(e) {
    if (props.canEdit && !signed) {
      const containerRect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - containerRect.left + e.currentTarget.scrollLeft;
      const y = e.clientY - containerRect.top + e.currentTarget.scrollTop;

      const { width, height } = pageDimensions;

      const relativeX = (x / containerRect.width) * width;
      const relativeY = ((containerRect.height - y) / containerRect.height) * height;

      if (selectedTool === 'text') {
        setTextInputPosition({ x: relativeX, y: relativeY, clientX: x, clientY: y });
        setShowTextInput(true);
      } else if (selectedTool === 'signature') {
        setElements(prevElements => [...prevElements, { type: selectedTool, x: relativeX, y: relativeY, pageNumber }]);
      } else if (selectedTool === 'X') {
        setElements(prevElements => [...prevElements, { type: selectedTool, x: relativeX, y: relativeY, pageNumber }]);
      }
    }
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function nextPage() {
    if (pageNumber < numPages) {
      setPageNumber(prevPage => prevPage + 1);
    }
  }

  function backPage() {
    if (pageNumber > 1) {
      setPageNumber(prevPage => prevPage - 1);
    }
  }

  // Función de alerta estandarizada
  const showAlert = (title, message, type) => {
    Store.addNotification({
      title: title,
      message: message,
      type: type,
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
  };

  async function modifyPdf(acceptedStatus = accepted) {
    if (!initialPdfBytes) return;
    const pdfDoc = await PDFDocument.load(initialPdfBytes, { ignoreEncryption: true });
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const pages = pdfDoc.getPages();

    let elementsModified = false;

    if (elements.length > 0) {
      elementsModified = true;
      for (const element of elements) {
        const { type, x, y, text, pageNumber: elemPage } = element;
        const pageIndex = elemPage - 1;
        if (pageIndex >= pages.length) continue;

        const page = pages[pageIndex];

        if (type === 'signature') {
          let imageBytes;
          if (text && text.startsWith('data:image')) {
            imageBytes = await fetch(text).then((res) => res.arrayBuffer());
          } else if (signature) {
            imageBytes = await fetch(signature).then((res) => res.arrayBuffer());
          }

          if (imageBytes) {
            const pngImage = await pdfDoc.embedPng(imageBytes);
            const pngDims = pngImage.scaleToFit(100, 50);
            page.drawImage(pngImage, {
              x: x - pngDims.width / 2,
              y: y - pngDims.height / 2,
              width: pngDims.width,
              height: pngDims.height,
            });
          }
        } else if (type === 'X') {
          page.drawText('X', {
            x: x - 5,
            y: y - 5,
            size: 20,
            font: helveticaFont,
            color: rgb(0, 0, 0),
          });
        } else if (type === 'text') {
          page.drawText(text, {
            x: x,
            y: y,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
          });
        }
      }
    }

    if (!acceptedStatus) {
      elementsModified = true;
      const rejectText = 'RECHAZADO';
      const fontSize = 100;
      const opacity = 0.3;
      const angle = -45;

      pages.forEach((page) => {
        const { width, height } = page.getSize();

        page.drawText(rejectText, {
          x: (width / 2) - (helveticaFont.widthOfTextAtSize(rejectText, fontSize) / 2),
          y: (height / 2) - (fontSize / 2),
          size: fontSize,
          font: helveticaFont,
          color: rgb(1, 0, 0),
          rotate: degrees(angle),
          opacity: opacity,
        });
      });
    }

    if (elementsModified) {
      const pdfBytes = await pdfDoc.save();
      setBytes(pdfBytes);
      previsualizar(pdfBytes);
      setSigned(true);

      // Guardar los elementos en el estado savedElements
      setSavedElements(elements);

      // Determinar el estado del documento
      const hasSignature = elements.some((element) => element.type === 'signature');
      if (!acceptedStatus) {
        setEstado('RECHAZADO');
      } else if (hasSignature) {
        setEstado('ACEPTADO');
      } else {
        setEstado('EDICION');
        showAlert('Para aceptar el documento en el sistema, deberá agregar una firma.', '', 'warning');
      }

      setShowConfirmTooltip(true);
      // Ocultar el tooltip después de 3 segundos
      setTimeout(() => {
        setShowConfirmTooltip(false);
      }, 3000);

      // Limpiar elementos para evitar duplicados
      setElements([]);

      // Retornar los bytes del PDF modificado
      return pdfBytes;
    } else {
      showAlert('No se han realizado modificaciones al documento.', '', 'warning');
      return;
    }
  }

  useEffect(() => {
    if (props.canEdit && !signed && profileState.nombre && numPages) {
      const extractTextFromLastPage = async () => {
        try {
          const loadingTask = pdfjs.getDocument(documentView);
          const pdf = await loadingTask.promise;
          const pageNumber = numPages; // última página
          const page = await pdf.getPage(pageNumber);
          const textContent = await page.getTextContent();

          // textContent.items es un array de elementos de texto
          const items = textContent.items;
          let name = "francisco"
          items.forEach(item => {
            if (
              item.str &&
              item.str.toLowerCase().includes(name.toLowerCase())
            ) {
              const transform = item.transform;
              const x = transform[4];
              const y = transform[5];
              console.log(
                `Encontrado '${name}' en x: ${x}, y: ${y} en la página ${pageNumber}`
              );
            }
          });
        } catch (error) {
          console.error('Error al extraer texto de la última página:', error);
        }
      };
      extractTextFromLastPage();
    }
  }, [props.canEdit, signed, profileState.nombre, numPages, documentView]);

  function resetSignatures() {
    setElements([]);
    setSavedElements([]);
    setSigned(false);
    setAccepted(true);
    setDocumentView(props.pdf);
  }

  function rejectDocument() {
    setAccepted(false);
    modifyPdf(false);
  }

  const handleRegistrarModificaciones = async () => {
    await registrarModificaciones();
  };

  function base64Encode(arrayBuffer) {
    const byteArray = new Uint8Array(arrayBuffer);
    let binaryString = '';
    for (let i = 0; i < byteArray.byteLength; i++) {
      binaryString += String.fromCharCode(byteArray[i]);
    }
    return btoa(binaryString);
  }

  async function handleCerrarDocumento() {
    setLoading(true); // Iniciar carga
    try {
      // Generate modified PDF
      const pdfBytes = await modifyPdf();
      // Convert to base64
      /* const pdfBase64 = btoa(String.fromCharCode(...new Uint8Array(pdfBytes)));  */
      const pdfBase64 = base64Encode(pdfBytes)
      // Prepare data
      const data = {
        id_documento: idDocumento.toString(),
        nombre_documento: docName,
        documentob64: pdfBase64,
      };
      console.log(data)
      // Send to endpoint
      let token = localStorage.getItem("token");
      const headers = {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      };
      await axios.post(enviroment + '/cerrar-documento/', data, { headers });
      setLoading(false); // Finalizar carga
      // Notify success and update
      if (onClosePDF) {
        onClosePDF('Documento cerrado exitosamente', 'success');
      }
      if (onDocumentUpdated) {
        onDocumentUpdated();
      }
    } catch (error) {
      setLoading(false); // Finalizar carga
      console.error('Error al cerrar documento:', error);
      if (onClosePDF) {
        onClosePDF('Error al cerrar documento', 'danger');
      }
    }
  }

  async function registrarModificaciones() {
    setLoading(true); // Iniciar carga
    const id_documento = idDocumento;
    const id_receptor = profileState.userId;

    const newElements = savedElements.filter(el => !elementsFromEndpoint.some(epEl => {
      return el.type === epEl.tipo &&
        el.x === epEl.coordenada_x &&
        el.y === epEl.coordenada_y &&
        el.text === epEl.texto &&
        el.pageNumber === epEl.pagina;
    }));

    const body = {
      id_documento: id_documento,
      id_receptor: id_receptor,
      data: newElements,
      estado: estado,
    };

    let token = localStorage.getItem("token");
    const headers = {
      "Accept": "application/json",
      "Authorization": `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    try {
      await axios.post(enviroment + '/registrar-modificaciones/', body, { headers });
      setLoading(false);
      setAccepted(true);

      // Notifica al componente padre que debe actualizar las listas
      if (onDocumentUpdated) {
        onDocumentUpdated();
      }

      // Cerrar el documento y mostrar alerta de éxito
      if (onClosePDF) {
        onClosePDF('Edición registrada exitosamente', 'success');
      }

    } catch (error) {
      setLoading(false);
      console.error("Error al registrar modificaciones:", error);
      // Mostrar alerta de error
      if (onClosePDF) {
        onClosePDF('Error al registrar modificaciones', 'danger');
      }
    }
  }

  const handleDownloadDocument = () => {
    const link = document.createElement('a');
    link.href = documentView;
    link.download = docName || 'documento.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePlaceSignatureForActaMateriales = async () => {
    try {
      const loadingTask = pdfjs.getDocument(documentView);
      const pdf = await loadingTask.promise;
      const pageNumberToSearch = numPages; // Last page
      const page = await pdf.getPage(pageNumberToSearch);
      const textContent = await page.getTextContent();

      const items = textContent.items;
      let name = `${profileState.nombre}`;
      let lastPosition = null;

      let y_ = 9999
      items.forEach(item => {
        if (item.str && item.str.toLowerCase().includes(name.toLowerCase())) {
          const [, , tx, ty, x, y] = item.transform;
          if (y < y_) {
            y_ = y
            lastPosition = { x, y };
            console.log(x, y)
          }
        }
      });

      if (lastPosition) {
        const signatureX = lastPosition.x + 30;
        const signatureY = lastPosition.y + 20; // Adjust as necessary
        setElements(prevElements => [...prevElements, {
          type: 'signature',
          x: signatureX,
          y: signatureY,
          pageNumber: pageNumberToSearch,
        }]);
      } else {
        showAlert('No se encontró su nombre en el documento.', '', 'warning');
      }
    } catch (error) {
      console.error('Error al colocar la firma:', error);
    }
  };
  const handlePlaceSignatureForActaServicios = async () => {
    try {
      const loadingTask = pdfjs.getDocument(documentView);
      const pdf = await loadingTask.promise;

      let found = false;
      for (let pageNum = 1; pageNum <= numPages; pageNum++) {
        const page = await pdf.getPage(pageNum);
        const textContent = await page.getTextContent();
        const items = textContent.items;

        for (let item of items) {
          if (item.str && item.str.toLowerCase().includes('firma y nombre sub gerente y/o jefatura')) {
            const [, , tx, ty, x, y] = item.transform;
            const signatureX = x + 200;
            const signatureY = y + 100; // Adjust as necessary
            setElements(prevElements => [...prevElements, {
              type: 'signature',
              x: signatureX,
              y: signatureY,
              pageNumber: pageNum,
            }]);
            found = true;
            break;
          }
        }
        if (found) break;
      }

      if (!found) {
        showAlert('No se encontró "Firma y Nombre" en el documento.', '', 'warning');
      }
    } catch (error) {
      console.error('Error al colocar la firma:', error);
    }
  };
  const handlePlaceSignatureForCertificadoExcepcion = async () => {
    try {
      const loadingTask = pdfjs.getDocument(documentView);
      const pdf = await loadingTask.promise;
      const pageNumberToSearch = numPages; // Last page
      const page = await pdf.getPage(pageNumberToSearch);
      const textContent = await page.getTextContent();

      const items = textContent.items;
      let name = `${profileState.nombre}`;
      let lastPosition = null;

      items.forEach(item => {
        if (item.str && item.str.toLowerCase().includes(name.toLowerCase())) {
          const [, , tx, ty, x, y] = item.transform;
          lastPosition = { x, y };
        }
      });

      if (lastPosition) {
        const signatureX = lastPosition.x + 300; // Adjust as necessary
        const signatureY = lastPosition.y - 15;
        setElements(prevElements => [...prevElements, {
          type: 'signature',
          x: signatureX,
          y: signatureY,
          pageNumber: pageNumberToSearch,
        }]);
      } else {
        showAlert('No se encontró su nombre en el documento.', '', 'warning');
      }
    } catch (error) {
      console.error('Error al colocar la firma:', error);
    }
  };

  const handlePlaceSignatureForformularioCompra = async () => {
    try {
      const loadingTask = pdfjs.getDocument(documentView);
      const pdf = await loadingTask.promise;
      const pageNumberToSearch = numPages; // Last page
      const page = await pdf.getPage(pageNumberToSearch);
      const textContent = await page.getTextContent();

      const items = textContent.items;
      let name = `${profileState.nombre}` ;
      let lastPosition = null;
      let y_ = 9999
      items.forEach(item => {
        if (item.str && item.str.toLowerCase().includes(name.toLowerCase())) {
          const [, , tx, ty, x, y] = item.transform;
          if (y < y_) {
            y_ = y
            lastPosition = { x, y };
            console.log(x, y)
          }
        }
      });

      if (lastPosition) {
        const signatureX = lastPosition.x + 40;
        const signatureY = lastPosition.y + 25; // Adjust as necessary (you might need to subtract depending on coordinate system)
        setElements(prevElements => [...prevElements, {
          type: 'signature',
          x: signatureX,
          y: signatureY,
          pageNumber: pageNumberToSearch,
        }]);
        // Optionally, navigate to the page where the signature was placed
        setPageNumber(pageNumberToSearch);
      } else {
        showAlert('No se encontró su nombre en el documento.', '', 'warning');
      }
    } catch (error) {
      console.error('Error al colocar la firma:', error);
      showAlert('Error al colocar la firma.', '', 'danger');
    }
  };

  return (
    <div className="document-wrapper">
      <Backdrop style={{ zIndex: 9999 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="document-viewer" ref={pdfWrapperRef} style={{ position: 'relative' }}>
        <div id="tooltip_container" style={{ position: 'relative' }} onClick={handleClick}>
          <Document
            file={documentView}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<div>Cargando documento...</div>}
            noData={<div>No se encontró el documento.</div>}
          >
            <div style={{ border: '1px solid gray', position: 'relative' }}>
              <Page
                pageNumber={pageNumber}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                onLoadSuccess={({ width, height }) => setPageDimensions({ width, height })}
              />
            </div>
          </Document>
          {/* Overlay */}
          <div
            className="overlay"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              pointerEvents: 'none',
            }}
          >
            {elements
              .filter(el => el.pageNumber === pageNumber)
              .map((element, index) => {
                const { x, y, type, text } = element;
                const style = {
                  position: 'absolute',
                  left: `${(x / pageDimensions.width) * 100}%`,
                  top: `${(1 - (y / pageDimensions.height)) * 100}%`,
                  transform: 'translate(-50%, -50%)',
                };

                if (type === 'signature') {
                  let imgSrc = signature; // default to user's signature
                  if (text && text.startsWith('data:image')) {
                    imgSrc = text;
                  }
                  return (
                    <img
                      key={index}
                      src={imgSrc}
                      alt="Firma"
                      style={{ ...style, width: '100px', height: '50px' }}
                    />
                  );
                } else if (type === 'X') {
                  return (
                    <div key={index} style={{ ...style, color: 'black', fontSize: '20px' }}>X</div>
                  );
                } else if (type === 'text') {
                  return (
                    <div key={index} style={{ ...style, color: 'black', fontSize: '12px' }}>{text}</div>
                  );
                } else {
                  return null;
                }
              })}
            {showTextInput && (
              <div
                style={{
                  position: 'absolute',
                  left: `${(textInputPosition.x / pageDimensions.width) * 100}%`,
                  top: `${(1 - (textInputPosition.y / pageDimensions.height)) * 100}%`,
                  transform: 'translate(-50%, -50%)',
                  pointerEvents: 'auto',
                }}
              >
                <TextField
                  value={textInputValue}
                  onChange={(e) => setTextInputValue(e.target.value)}
                  onBlur={() => {
                    if (textInputValue.trim() !== '') {
                      setElements((prevElements) => [
                        ...prevElements,
                        {
                          type: 'text',
                          x: textInputPosition.x,
                          y: textInputPosition.y,
                          text: textInputValue,
                          pageNumber,
                        },
                      ]);
                    }
                    setTextInputValue('');
                    setShowTextInput(false);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (textInputValue.trim() !== '') {
                        setElements((prevElements) => [
                          ...prevElements,
                          {
                            type: 'text',
                            x: textInputPosition.x,
                            y: textInputPosition.y,
                            text: textInputValue,
                            pageNumber,
                          },
                        ]);
                      }
                      setTextInputValue('');
                      setShowTextInput(false);
                    }
                  }}
                  autoFocus
                  size="small"
                  variant="outlined"
                />
              </div>
            )}
          </div>

        </div>
      </div>

      <div className="document-sidebar">
        {/* Navegación */}
        <Tooltip title="Página anterior" placement='left'>
          <span>
            <IconButton onClick={backPage} disabled={pageNumber <= 1}>
              <ArrowBackIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Página siguiente" placement='left'>
          <span>
            <IconButton onClick={nextPage} disabled={pageNumber >= numPages}>
              <ArrowForwardIcon />
            </IconButton>
          </span>
        </Tooltip>

        {/* Herramientas de edición */}
        {props.canEdit && !signed && (
          <>
            {documentType === 'acta_de_materiales' && (
              <Tooltip title="Firmar Acta de Materiales" placement='left'>
                <IconButton onClick={handlePlaceSignatureForActaMateriales}>
                  <AutoFixHighIcon /> {/* Or another appropriate icon */}
                </IconButton>
              </Tooltip>
            )}
            {documentType === 'acta_de_servicios' && (
              <Tooltip title="Firmar Acta de Servicio" placement='left'>
                <IconButton onClick={handlePlaceSignatureForActaServicios}>
                  <AutoFixHighIcon />
                </IconButton>
              </Tooltip>
            )}
            {documentType === 'certificado_de_excepcion' && (
              <Tooltip title="Firmar Certificado de Excepción" placement='left'>
                <IconButton onClick={handlePlaceSignatureForCertificadoExcepcion}>
                  <AutoFixHighIcon />
                </IconButton>
              </Tooltip>
            )}
            {documentType === 'formulario_compra' && (
              <Tooltip title="Firmar Formulario de compra" placement='left'>
                <IconButton onClick={handlePlaceSignatureForformularioCompra}>
                  <AutoFixHighIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Insertar Firma" placement='left'>
              <span>
                <IconButton
                  className={`tool-button ${selectedTool === 'signature' ? 'selected' : ''}`}
                  onClick={() => {
                    setSelectedTool('signature');
                    setShowTextInput(false);
                  }}
                  disabled={!signature}
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Ingresar Texto" placement='left'>
              <IconButton
                className={`tool-button ${selectedTool === 'text' ? 'selected' : ''}`}
                onClick={() => {
                  if (selectedTool === 'text') {
                    setSelectedTool('');
                    setShowTextInput(false);
                  } else {
                    setSelectedTool('text');
                  }
                }}
              >
                <TextFieldsIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Dibujar "X"' placement='left'>
              <IconButton
                className={`tool-button ${selectedTool === 'X' ? 'selected' : ''}`}
                onClick={() => {
                  setSelectedTool('X');
                  setShowTextInput(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>

            {/* Acciones de edición */}
            <Tooltip title="Reiniciar firmas" placement='left'>
              <IconButton onClick={resetSignatures}>
                <LoopIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Finalizar edición" placement='left'>
              <IconButton onClick={modifyPdf}>
                <DoneIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Rechazar documento" placement='left'>
              <IconButton onClick={rejectDocument}>
                <CancelIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
        {canCloseDocument && estadoDoc !== 'CERRADO' && (
          <Tooltip title="Cerrar Documento" placement='left'>
            <IconButton onClick={handleCerrarDocumento}>
              <LockIcon />
            </IconButton>
          </Tooltip>
        )}
        {estadoDoc === 'CERRADO' && (
          <Tooltip title="Descargar Documento" placement='left'>
            <IconButton onClick={handleDownloadDocument}>
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        )}

        {/* Después de edición */}
        {signed && (savedElements.length > 0 || !accepted) && (

          <>
            <Tooltip
              title={showConfirmTooltip ? "Debe confirmar para realizar acción" : "Confirmar"}
              open={showConfirmTooltip}
              disableHoverListener
              disableFocusListener
              disableTouchListener
              placement='left'
              onClose={() => setShowConfirmTooltip(false)}
            >
              <Tooltip title="Confirmar" placement='left'>
                <IconButton onClick={handleRegistrarModificaciones}>
                  <DoneIcon />
                </IconButton>
              </Tooltip>

            </Tooltip>

            <Tooltip title="Reiniciar firmas" placement='left'>
              <IconButton onClick={resetSignatures}>
                <LoopIcon />
              </IconButton>
            </Tooltip>

          </>
        )}
      </div>

    </div>
  );
};

export default PdfViewer;
