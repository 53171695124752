import React , {useState,createContext,useEffect, useContext} from 'react';

export const AlgoritmoFlag = createContext();

export const AlgoritmoFlagProvider = props =>{
    const [algoritmoFlag, setAlgoritmoFlag] = useState(true)
    
    const value = [algoritmoFlag, setAlgoritmoFlag]
    return (
      <AlgoritmoFlag.Provider value={value}>
        {props.children}
      </AlgoritmoFlag.Provider>
    )
  }
