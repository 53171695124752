import React , {useState,createContext,useEffect, useContext} from 'react';

export const sgSearch = createContext();

export const SgSearchProvider = props =>{
    const [currentSgSearch, setCurrentSgSearch] = useState(['none','ds'])
    

    const value = [currentSgSearch, setCurrentSgSearch]
    return (
      <sgSearch.Provider value={value}>
        {props.children}
      </sgSearch.Provider>
    )
  }
