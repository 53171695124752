import React, { useEffect, useContext, useState } from 'react';
import { LoggedContext } from './context/LoggedContext';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import Login from './pages/auth/login/login';
import Forgot from './pages/auth/forgot/forgot';
import Inside from './pages/inside/inside';
import { EnviromentsProvider } from './context/Enviroments';
import { Enviroments2Provider } from './context/Enviroments2';
import { DashProvider } from './context/dashContext';
import { LastDashProvider } from './context/lastDashContext';
import { LastPageProvider } from './context/lastPageContext';
import { OltSearchProvider } from './context/OltSearch';
import { SgSearchProvider } from './context/SgSearchContext'
import { TbuWeekProvider } from './context/tbuWeekContext';
import { CurrentPageProvider } from './context/PageContext';
import { UserProvider } from './context/UserContext';
import { SignaturesProvider } from './context/SignaturesContext';
import { SdmhWeekProvider } from './context/sdmhWeekContext';
import { SimulatorPlanProvider } from './context/simulatorPlanContext';
import { ChangeTputProvider } from './context/tputSimulatorContext';
import { AlgoritmoFlagProvider } from './context/algoritmoFlagContext';
import { UpdateDiagramProvider } from './context/updateDiagramContext';
import { WeekSimulatorProvider } from './context/weekSimulatorContext';
import { UpdateDiagramLabelsProvider } from './context/updateDiagramLabelsContext';
import Activate from './pages/auth/activate/activate';

const ProtectedRoute = ({ component: Component, ...rest }) => {
        const [loggedIn] = useContext(LoggedContext);
        return (
                <Route
                        {...rest}
                        render={(props) =>
                                loggedIn ? (
                                        <Component {...props} />
                                ) : (
                                        <Redirect to="/" />
                                )
                        }
                />
        );
};


const protectedPaths = [
        '/test',
        '/home',
        '/profile',
        '/signatures',
        '/gestion-acceso'
];

const Routes_ = (props) => {

        const [loggedIn, setLoggedIn] = useContext(LoggedContext);
        return (
                < EnviromentsProvider >
                        <Enviroments2Provider>
                                <UserProvider>
                                        <DashProvider>
                                                <LastDashProvider>
                                                        <LastPageProvider>
                                                                <SignaturesProvider>
                                                                        <CurrentPageProvider>
                                                                                <OltSearchProvider>
                                                                                        <SgSearchProvider>
                                                                                                <TbuWeekProvider>
                                                                                                        <SdmhWeekProvider>
                                                                                                                <SimulatorPlanProvider>
                                                                                                                        <UpdateDiagramProvider>
                                                                                                                                <ChangeTputProvider>
                                                                                                                                        <WeekSimulatorProvider>
                                                                                                                                                <UpdateDiagramLabelsProvider>
                                                                                                                                                        <AlgoritmoFlagProvider>
                                                                                                                                                                <Switch>
                                                                                                                                                                        {console.log(loggedIn)}
                                                                                                                                                                        <Route exact path="/">
                                                                                                                                                                                {loggedIn ? <Redirect to="/home" /> : <Login />}
                                                                                                                                                                        </Route>
                                                                                                                                                                  {/*       <Route exact path="/forgot">
                                                                                                                                                                                {loggedIn ? <Redirect to="/home" /> : <Forgot />}
                                                                                                                                                                        </Route>
                                                                                                                                                                        <Route exact path="/activate">
                                                                                                                                                                                {loggedIn ? <Redirect to="/home" /> : <Activate />}
                                                                                                                                                                        </Route> */}
                                                                                                                                                                        {protectedPaths.map((path) => (
                                                                                                                                                                                <ProtectedRoute key={path} path={path} component={Inside} />
                                                                                                                                                                        ))}
                                                                                                                                                                </Switch>
                                                                                                                                                        </AlgoritmoFlagProvider>
                                                                                                                                                </UpdateDiagramLabelsProvider>

                                                                                                                                        </WeekSimulatorProvider>
                                                                                                                                </ChangeTputProvider>
                                                                                                                        </UpdateDiagramProvider>
                                                                                                                </SimulatorPlanProvider>
                                                                                                        </SdmhWeekProvider>
                                                                                                </TbuWeekProvider>
                                                                                        </SgSearchProvider>
                                                                                </OltSearchProvider>
                                                                        </CurrentPageProvider>
                                                                </SignaturesProvider>
                                                        </LastPageProvider>
                                                </LastDashProvider>
                                        </DashProvider>
                                </UserProvider>
                        </Enviroments2Provider>
                </EnviromentsProvider >


        )
}

export default withRouter(Routes_);