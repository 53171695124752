import React , {useState,createContext,useEffect, useContext} from 'react';

export const UpdateDiagram = createContext();

export const UpdateDiagramProvider = props =>{
    const [updateDiagramFlag, setUpdateDiagramFlag] = useState(false)
    

    const value = [updateDiagramFlag, setUpdateDiagramFlag]
    return (
      <UpdateDiagram.Provider value={value}>
        {props.children}
      </UpdateDiagram.Provider>
    )
  }
