// menuConfig.js

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import PolicyIcon from '@mui/icons-material/Policy';
import SpaIcon from '@mui/icons-material/Spa';

export const menuConfig = {
  ftth: {
    menuList: [
      { name: 'Home', icon: HomeOutlinedIcon, link: '/ftth/home' },
      { name: 'Current Status', icon: QueryStatsOutlinedIcon, link: '/ftth/current-status' },
      { name: 'Historical Profile', icon: PermContactCalendarOutlinedIcon, link: '/ftth/historical-profile' },
      { name: 'Forecast', icon: AutoGraphIcon, link: '/ftth/forecast' },
      { name: "OLT's Status", icon: DnsOutlinedIcon, link: '/ftth/olt-status' },
      { name: 'Search by OLT', icon: ManageSearchIcon, link: '/ftth/search-by-olt' },
      { name: 'PPON Status', icon: SettingsInputComponentIcon, link: '/ftth/pon' },
      { name: 'BNG Status', icon: CorporateFareIcon, link: '/ftth/bng' },
      { name: 'Download Data', icon: DownloadIcon, link: '/ftth/download-data' },
    ],
    editorList: [
      { name: 'Upload Data', icon: UploadIcon, link: '/ftth/upload-data' },
    ],
  },
  hfc: {
    menuList: [
      { name: 'Home', icon: HomeOutlinedIcon, link: '/hfc/home' },
      { name: 'Current Status', icon: QueryStatsOutlinedIcon, link: '/hfc/current-status' },
      { name: 'Performance', icon: ShowChartIcon, link: '/hfc/performance' },
      { name: 'TBU & INP SG', icon: EngineeringIcon, link: '/hfc/tbu-inp' },
      { name: 'Forecast', icon: AutoGraphIcon, link: '/hfc/forecast' },
      { name: 'Search by SG', icon: ManageSearchIcon, link: '/hfc/search-by-sg' },
      { name: 'SDMH', icon: GppMaybeIcon, link: '/hfc/sdmh' },
      { name: 'SDMH by CMTS', icon: PolicyIcon, link: '/hfc/sdmh-by-cmts' },
      { name: 'Download Data', icon: DownloadIcon, link: '/hfc/download-data' },
    ],
    editorList: [],
  },
  operationsList: [
    { name: 'VTR', color: '#EB5757', link: '/vtr' },
    // Agrega más operaciones si es necesario
  ],
};