import React , {useState,createContext,useEffect, useContext} from 'react';

export const LastDashboardType = createContext();

export const LastDashProvider = props =>{
    const [lastDashType,setLastDashType] = useState('');
    

    const value = [lastDashType,setLastDashType]
    return (
      <LastDashboardType.Provider value={value}>
        {props.children}
      </LastDashboardType.Provider>
    )
  }
