import React , {useState,createContext,useEffect, useContext} from 'react';

export const SimulatorPlan = createContext();

export const SimulatorPlanProvider = props =>{
    const [planSelected ,setPlanSelected] = useState(null);
    

    const value = [planSelected, setPlanSelected]
    return (
      <SimulatorPlan.Provider value={value}>
        {props.children}
      </SimulatorPlan.Provider>
    )
  }
