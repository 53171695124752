import React, { useState, createContext, useEffect, useContext } from 'react';
import { UserContext } from './UserContext';
import { Enviroments } from './Enviroments';

import axios from 'axios';
export const SignaturesContext = createContext();


export const SignaturesProvider = props => {
  const [signature, setSignature] = useState(null)

  const [enviroment] = useContext(Enviroments);
  const [profileState] = useContext(UserContext);

  async function getSignature() {
    const token = localStorage.getItem('token');
    const headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
    };

    await axios(enviroment + '/get-signature/' + profileState.userId, { headers })
      .then((response) => {
        setSignature(response.data);
      })
      .catch((error) => {
      });
  }

  useEffect(() => {

    getSignature();

  }, [profileState.username]);

  const value = [signature, setSignature]
  return (
    <SignaturesContext.Provider value={value}>
      {props.children}
    </SignaturesContext.Provider>
  )
}
