
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import home from './lobby.scss'
import { styled } from '@mui/system';
import { DashboardType } from '../../../context/dashContext';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import { CardActionArea } from '@mui/material';
import { Grid, Container } from '@mui/material';
import RateReviewIcon from '@mui/icons-material/RateReview';
import InsightsIcon from '@mui/icons-material/Insights';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { CurrentPage } from '../../../context/PageContext';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { createTheme, ThemeProvider } from '@mui/material';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import AnnouncementIcon from '@mui/icons-material/Announcement';

const theme = createTheme({
    components: {
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '& > *': {
                        padding: '10px !important', // Cambia este valor según tus necesidades
                    },
                },
            },
        },
    },
});
const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        border: '1px solid #dadde9',
    },
}));



const Lobby = () => {

    const [currentPage, setCurrentPage] = React.useContext(CurrentPage);
    const history = useHistory();
    const [dashType, setDashType] = React.useContext(DashboardType);
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [menuName, setMenuName] = React.useState("Home");
    const [currentMenuName, setCurrentMenuName] = React.useState("Home");
    const [operationName, setOperationName] = React.useState("vtr");
    const isMenuOpen = Boolean(anchorEl);

    const menuList = [
       
        { 'name': 'Gestión Acceso','clase': 'card-header-icon gestion_acceso' , 'icon': InsightsIcon, 'link': '/gestion-acceso', 'dash': 'gestion-acceso', 'description': 'Despliegue visual gráficos Gestión de Acceso.'},
        { 'name': 'Firmas','clase': 'card-header-icon gestion_acceso' , 'icon': RateReviewIcon, 'link': '/signatures', 'dash': 'signatures', 'description': 'Módulo de firma de documentos.'},
    ]

    const operationsList = [
        { 'name': 'VTR', 'color': '#EB5757', 'link': "/vtr" },
        /*{ 'name': 'Cabletica', 'color': '#F2994A', 'link': "/cabletica" },
        { 'name': 'LPR', 'color': '#219653', 'link': '/lpr' },
        { 'name': 'Panamá', 'color': '#2D9CDB', 'link': '/panama' },*/
    ]



    const onListClick = e => {
        let name = e.currentTarget.getAttribute('name');
        setMenuName(name);
        setCurrentPage(e.currentTarget.getAttribute('page'))
        setDashType(e.currentTarget.getAttribute('dash'))
        history.push(e.currentTarget.getAttribute('to'));

        setOpen(false);
        window.scroll(0, 0)
        /*if (!open) {
            setOpen(true);
        }*/

    }



    return (
        <div className="lobby">


            <Grid
                container
                direction="row"
                spacing={1}
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    height: '80vh'
                }}
            >
                {menuList.map((element, index) => (
                    <Grid item key={index} className="cardcontainer">

                        <HtmlTooltip
                            placement="top"
                            title={
                                <React.Fragment>
                                    <Typography fontSize={25} fontWeight={30} color={"inherit"}>{element.name}</Typography>
                                    <Typography fontSize={15} color="inherit">{element.description}</Typography>
                                    
                                </React.Fragment>
                            }
                        >
                            <Card sx={{ width: 200, height: 200 }}>
                                <CardActionArea sx={{ height: '100%' }} to={element.link} onClick={onListClick}>
                                    <div className="card-header">
                                        <element.icon className={element.clase} />
                                    </div>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {element.name}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </HtmlTooltip>

                    </Grid>
                ))}
            </Grid>
            {/* <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
            >
                {menuList.map((item, index) => (
                    <Grid item key={index}>
                        <Card
                            sx={{
                                borderRadius: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 2,
                                height: 150,
                                width: 150,
                                background: (theme) => `linear-gradient(135deg, ${theme.palette.primary.main} 30%, transparent 100%)`,
                                boxShadow: 3,
                            }}
                        >
                            <CardContent>
                                <div style={{ fontSize: 40, marginBottom: 8 }}>{item.icon}</div>
                                <Typography variant="h6">{item.name}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid> */}

            <div className="waveWrapper waveAnimation">
                <div className="waveWrapperInner bgTop">
                    <div className="wave waveTop" style={{ backgroundImage: "url('http://front-end-noobs.com/jecko/img/wave-top.png')" }}></div>
                </div>
                <div className="waveWrapperInner bgMiddle">
                    <div className="wave waveMiddle" style={{ backgroundImage: "url('http://front-end-noobs.com/jecko/img/wave-mid.png')" }}></div>
                </div>
                <div className="waveWrapperInner bgBottom">
                    <div className="wave waveBottom" style={{ backgroundImage: "url('http://front-end-noobs.com/jecko/img/wave-bot.png')" }}></div>
                </div>
            </div>



        </div>
    )
}

export default Lobby;
