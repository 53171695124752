// Header.jsx

import React, { useState, useContext } from 'react';
import {
    AppBar as MuiAppBar,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';
import { useHistory, useLocation } from 'react-router-dom';
import { UserContext } from '../../../../context/UserContext';
import { DashboardType } from '../../../../context/dashContext';
import { LoggedContext } from '../../../../context/LoggedContext';

// Mover AppBarStyled fuera del componente
const AppBarStyled = styled(MuiAppBar)(({ theme, dashType }) => ({
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor:
        dashType === 'ftth'
            ? '#F26522'
            : dashType === 'hfc'
                ? '#4C6C9C'
                : dashType === 'simulator'
                    ? '#371d3c'
                    : dashType === 'calidad'
                        ? '#1fc0ab'
                        : dashType === 'core'
                            ? '#428859'
                            : dashType === 'cmts'
                                ? '#4f69db'
                                : dashType === 'incidencias'
                                    ? '#94306a'
                                    : dashType === 'home'
                                        ? 'rgba(255,255,255,0)'
                                        : '#0e0c0f',
}));

const Header = ({ dashType, backToHome, toggleSideMenu, isSideMenuOpen }) => {
    const history = useHistory();
    const location = useLocation();
    const [profileState] = useContext(UserContext);
    const [, setDashType] = useContext(DashboardType);
    const [LoggedIn, setLoggedIn] = useContext(LoggedContext);

    const [anchorElProfile, setAnchorElProfile] = useState(null);
    const [anchorElChange, setAnchorElChange] = useState(null);

    const handleProfileMenuOpen = (event) => {
        setAnchorElProfile(event.currentTarget);
    };

    const handleChangeMenuOpen = (event) => {
        setAnchorElChange(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setAnchorElProfile(null);
    };

    const handleChangeMenuClose = () => {
        setAnchorElChange(null);
    };

    const changeDashType = (type) => {
        setDashType(type);
        history.push(`/${type}/home`);
        handleChangeMenuClose();
    };

    const onLogout = () => {
        localStorage.removeItem('token');
        setLoggedIn(false);
        history.push('/');
    };

    const isFirstCalidadView = dashType === 'calidad' && location.pathname === '/calidad/home';

    return (
        <AppBarStyled position="fixed" dashType={dashType}>
            <Toolbar className="top-bar">
                {dashType === 'ftth' || dashType === 'hfc' ? (
                    <>
                        <IconButton
                            color="inherit"
                            aria-label="toggle side menu"
                            onClick={toggleSideMenu}
                            edge="start"
                            sx={{ marginRight: '36px' }}
                        >
                            {isSideMenuOpen ? <ArrowBackIosIcon /> : <MenuIcon />}
                        </IconButton>
                        <IconButton
                            color="inherit"
                            aria-label="back to home"
                            onClick={backToHome}
                            edge="start"
                            sx={{ marginRight: '36px' }}
                        >
                            <HomeOutlinedIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
                            {dashType === 'ftth' ? 'Dashboard FTTH' : 'Dashboard HFC'}
                        </Typography>
                    </>
                ) : dashType === 'calidad' ? (
                    <>
                        <IconButton
                            color="inherit"
                            aria-label={isFirstCalidadView ? 'back to home' : 'go back'}
                            onClick={isFirstCalidadView ? backToHome : () => history.goBack()}
                            edge="start"
                            sx={{ marginRight: '36px' }}
                        >
                            {isFirstCalidadView ? <HomeOutlinedIcon /> : <ArrowBackIosIcon />}
                        </IconButton>
                        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
                            Calidad
                        </Typography>
                    </>
                ) : dashType === 'home' ? (
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
                        Lobby
                    </Typography>
                ) : (
                    <>
                        <IconButton
                            color="inherit"
                            aria-label="back to home"
                            onClick={backToHome}
                            edge="start"
                            sx={{ marginRight: '36px' }}
                        >
                            <HomeOutlinedIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
                            {dashType === 'simulator'
                                ? 'Simulador'
                                : dashType === 'core'
                                    ? 'Core'
                                    : dashType === 'cmts'
                                        ? 'Cmts'
                                        : dashType === 'incidencias'
                                            ? 'Incidencias'
                                            : 'Herramientas'}
                        </Typography>
                    </>
                )}
                <div>
                    {(dashType === 'ftth' || dashType === 'hfc' || dashType === 'simulator') && (
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="change dashboard"
                            aria-controls="change-menu"
                            aria-haspopup="true"
                            onClick={handleChangeMenuOpen}
                            color="inherit"
                        >
                            <ChangeCircleIcon />
                        </IconButton>
                    )}
                    {/* Menú de cambio de dashboard */}
                    <Menu
                        anchorEl={anchorElChange}
                        id="change-menu"
                        open={Boolean(anchorElChange)}
                        onClose={handleChangeMenuClose}
                    >
                        {dashType !== 'ftth' && (
                            <MenuItem onClick={() => changeDashType('ftth')}>Cambiar a FTTH</MenuItem>
                        )}
                        {dashType !== 'hfc' && (
                            <MenuItem onClick={() => changeDashType('hfc')}>Cambiar a HFC</MenuItem>
                        )}
                        {dashType !== 'simulator' && (
                            <MenuItem onClick={() => changeDashType('simulator')}>Cambiar a Simulador</MenuItem>
                        )}
                    </Menu>

                    <IconButton
                        size="large"
                        edge="end"
                        aria-label="account of current user"
                        aria-controls="profile-menu"
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        color="inherit"
                    >
                        <AccountCircleIcon />
                    </IconButton>
                    {/* Menú de perfil */}
                    <Menu
                        anchorEl={anchorElProfile}
                        id="profile-menu"
                        open={Boolean(anchorElProfile)}
                        onClose={handleProfileMenuClose}
                    >
                        {dashType !== 'home' && (
                            <MenuItem
                                onClick={() => {
                                    history.push('/home');
                                    handleProfileMenuClose();
                                }}
                            >
                                <HomeOutlinedIcon />&nbsp;Inicio
                            </MenuItem>
                        )}
                        <MenuItem
                            onClick={() => {
                                history.push('/profile');
                                handleProfileMenuClose();
                            }}
                        >
                            <AccountCircleIcon />&nbsp;Perfil
                        </MenuItem>
                        {profileState.rol === 'editor' && (
                            <MenuItem
                                onClick={() => {
                                    history.push('/admin');
                                    handleProfileMenuClose();
                                }}
                            >
                                <AdminPanelSettingsIcon />&nbsp;Administración
                            </MenuItem>
                        )}
                        <MenuItem onClick={onLogout}>
                            <LogoutIcon />&nbsp;Cerrar sesión
                        </MenuItem>
                    </Menu>
                </div>
            </Toolbar>
        </AppBarStyled>
    );
};

export default Header;
