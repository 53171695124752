import React , {useState,createContext,useEffect, useContext} from 'react';

export const LastPage = createContext();

export const LastPageProvider = props =>{
    const [lastPage,setLastPage] = useState('');
    

    const value = [lastPage,setLastPage]
    return (
      <LastPage.Provider value={value}>
        {props.children}
      </LastPage.Provider>
    )
  }
