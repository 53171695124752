// SideMenu.jsx

import React from 'react';
import {
  Drawer as MuiDrawer,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Collapse,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import SpaIcon from '@mui/icons-material/Spa';
import { menuConfig } from './menuConfig';
import mainLogo from '../../../../styles/img/icon.png';

const SideMenu = ({
  dashType,
  currentPage,
  setCurrentPage,
  history,
  profileState,
  isSideMenuOpen,
}) => {
  const theme = useTheme();
  const open = isSideMenuOpen;
  const drawerWidth = 240;

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `60px`,
    [theme.breakpoints.up('sm')]: {
      width: `60px`,
    },
  });

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: open ? 'space-between' : 'center',
  }));

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      color: '#8B8D9A',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    })
  );

  const [menuName, setMenuName] = React.useState('Home');
  const [operationName, setOperationName] = React.useState('VTR');

  const handleMenuItemClick = (name, link) => {
    setMenuName(name);
    setCurrentPage(name);
    if (
      name === 'Home' ||
      name === 'Search by OLT' ||
      name === 'Upload Data' ||
      name === 'Search by SG'
    ) {
      history.push(link);
    } else {
      setOperationName('VTR');
      history.push(link + '/vtr');
    }
    window.scrollTo(0, 0);
  };

  const handleOperationClick = (menuName, op, link) => {
    setOperationName(op.name);
    setCurrentPage(menuName);
    history.push(link + op.link);
    window.scrollTo(0, 0);
  };

  const menuList = menuConfig[dashType]?.menuList || [];
  const editorList = menuConfig[dashType]?.editorList || [];
  const operationsList = menuConfig.operationsList || [];

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        {open ? (
          <>
            <img className="logo" src={mainLogo} alt="Logo" />
            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
              {dashType === 'ftth'
                ? 'Dashboard FTTH'
                : dashType === 'hfc'
                ? 'Dashboard HFC'
                : 'Herramientas'}
            </Typography>
          </>
        ) : (
          <img className="logo" src={mainLogo} alt="Logo" />
        )}
      </DrawerHeader>
      <Divider />
      <List className="drawer-list">
        {menuList.map((item) => (
          <div key={item.name}>
            <ListItem
              className={
                item.name === currentPage ? 'drawer-list-item selected' : 'drawer-list-item'
              }
              button
              onClick={() => handleMenuItemClick(item.name, item.link)}
            >
              <Tooltip title={item.name} placement="right">
                <ListItemIcon
                  className={
                    item.name === currentPage
                      ? 'drawer-list-item-icon selected'
                      : 'drawer-list-item-icon'
                  }
                >
                  <item.icon />
                </ListItemIcon>
              </Tooltip>
              {open && <ListItemText primary={item.name} />}
            </ListItem>

            {item.name !== 'Home' &&
              item.name !== 'Search by OLT' &&
              item.name !== 'Upload Data' &&
              item.name !== 'Search by SG' &&
              operationsList.length > 0 && (
                <Collapse in={open && item.name === menuName} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {operationsList.map((op) => (
                      <ListItem
                        sx={{ pl: 4 }}
                        className={
                          item.name === currentPage && op.name === operationName
                            ? 'drawer-list-item selected'
                            : 'drawer-list-item'
                        }
                        button
                        key={op.name}
                        onClick={() => handleOperationClick(item.name, op, item.link)}
                      >
                        <ListItemIcon>
                          <SpaIcon style={{ color: op.color }} />
                        </ListItemIcon>
                        {open && <ListItemText primary={op.name} />}
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
          </div>
        ))}
      </List>
      <Divider />
      {profileState.rol === 'editor' && (
        <List className="drawer-list">
          {editorList.map((item) => (
            <ListItem
              className={
                item.name === currentPage ? 'drawer-list-item selected' : 'drawer-list-item'
              }
              button
              key={item.name}
              onClick={() => handleMenuItemClick(item.name, item.link)}
            >
              <Tooltip title={item.name} placement="right">
                <ListItemIcon
                  className={
                    item.name === currentPage
                      ? 'drawer-list-item-icon selected'
                      : 'drawer-list-item-icon'
                  }
                >
                  <item.icon />
                </ListItemIcon>
              </Tooltip>
              {open && <ListItemText primary={item.name} />}
            </ListItem>
          ))}
        </List>
      )}
    </Drawer>
  );
};

export default SideMenu;
