import React, { useContext, useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Enviroments } from '../../../../context/Enviroments';
import { LoggedContext } from '../../../../context/LoggedContext';
import { UserContext } from '../../../../context/UserContext';
import 'rc-slider/assets/index.css';
import PersonIcon from '@mui/icons-material/Person';
import Paper from '@mui/material/Paper';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ExtensionIcon from '@mui/icons-material/Extension';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Modal from '@mui/material/Modal';
import CreateIcon from '@mui/icons-material/Create';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Button from '@mui/material/Button';
import Send from '@mui/icons-material/Send';
import { Store } from 'react-notifications-component';
import { styled } from '@mui/material/styles';
import UploadSignature from '../../../shared/components/modal/uploadSignature';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import './profile.scss';

const Profile = () => {
  const history = useHistory();
  const [enviroment] = useContext(Enviroments);
  const [profileState] = useContext(UserContext);
  const [loggedIn, setLoggedIn] = useContext(LoggedContext);
  const [openPW, setOpenPW] = React.useState(false);
  const [openSign, setOpenSign] = React.useState(false);
  const handleOpenPW = () => setOpenPW(true);
  const handleClosePW = () => setOpenPW(false);
  const handleOpenSign = () => setOpenSign(true);
  const handleCloseSign = () => setOpenSign(false);

  const [valuesPW, setValuesPW] = React.useState({
    currentPassword: '',
    newPassword: '',
    newPasswordRepeat: '',
    showPasswordCP: false,
    showPasswordNP: false,
    showPasswordNPR: false,
  });

  // Estado para almacenar la firma actual
  const [signature, setSignature] = useState(null);

  // Función para obtener la firma actual del usuario
  const getSignature = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
    };

    try {
      //REVISAR USER ID GET SIGNATURE
      const response = await axios.get(`${enviroment}/get-signature/${profileState.userId}`, { headers });
      setSignature(response.data);
    } catch (error) {
      console.error('Error al obtener la firma:', error);
    }
  };

  useEffect(() => {
    getSignature();
  }, [profileState.username]);

  const alert_emptyImput = () => {
    Store.addNotification({
      title: 'Debe completar todos los campos.',
      message: ' ',
      type: 'warning',
      insert: 'bottom',
      container: 'bottom-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  };

  const alert_wrongPassword = () => {
    Store.addNotification({
      title: 'Error al actualizar contraseña.',
      message: ' ',
      type: 'danger',
      insert: 'bottom',
      container: 'bottom-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  };

  const alert_passwordsNotMatch = () => {
    Store.addNotification({
      title: 'Las nuevas contraseñas no coinciden.',
      message: ' ',
      type: 'danger',
      insert: 'bottom',
      container: 'bottom-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  };

  const alert_successChangePassword = () => {
    Store.addNotification({
      title: 'La contraseña se ha cambiado exitosamente.',
      message: ' ',
      type: 'success',
      insert: 'bottom',
      container: 'bottom-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  };

  async function changePassword() {
    let token = localStorage.getItem('token');
    const headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
    };

    const body = {
      username: profileState.username,
      newPassword: valuesPW.newPassword,
    };

    await axios
      .post(enviroment+'/update-password/', body, { headers })
      .then((response) => {
        console.log(response);
        if(response.data == 0){
          alert_wrongPassword();
        }else{
          alert_successChangePassword();
          localStorage.removeItem('token');
          setLoggedIn(false);
          history.push('/');
          setValuesPW({
            currentPassword: '',
            newPassword: '',
            newPasswordRepeat: '',
            showPasswordCP: false,
            showPasswordNP: false,
            showPasswordNPR: false,
          });
          setOpenPW(false);
        }
        
      })
      .catch((error) => {
        alert_wrongPassword();
      });
  }

  const onClick = (e) => {
    e.preventDefault();
    if (
      valuesPW.currentPassword === '' ||
      valuesPW.newPassword === '' ||
      valuesPW.newPasswordRepeat === ''
    ) {
      alert_emptyImput();
    } else if (valuesPW.newPassword !== valuesPW.newPasswordRepeat) {
      alert_passwordsNotMatch();
    } else {
      changePassword();
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '25px',
    boxShadow: 24,
    paddingBottom: 4
  };

  const handleClickShowPasswordCP = () => {
    setValuesPW({
      ...valuesPW,
      showPasswordCP: !valuesPW.showPasswordCP,
    });
  };

  const handleClickShowPasswordNP = () => {
    setValuesPW({
      ...valuesPW,
      showPasswordNP: !valuesPW.showPasswordNP,
    });
  };
  const handleClickShowPasswordNPR = () => {
    setValuesPW({
      ...valuesPW,
      showPasswordNPR: !valuesPW.showPasswordNPR,
    });
  };
  const handleChange = (prop) => (event) => {
    setValuesPW({ ...valuesPW, [prop]: event.target.value });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const Input = styled('input')({
    display: 'none',
  });

  return (
    <div className="profile">
      <div className="profile-top">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3}>
            <span className="profile-top-title">
              <PersonIcon className="icon" />
              Perfil de Usuario
            </span>
          </Grid>
        </Grid>
      </div>
      <div className="profile-content">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <div className="profile-content-section">
              <span className="profile-content-section-title">Información Personal</span>
              <Paper elevation={2} className="profile-content-section-atribute">
                <PersonIcon className="profile-content-section-atribute-icon" />
                <div className="profile-content-section-atribute-info">
                  <span className="profile-content-section-atribute-info-name">Nombre</span>
                  <span className="profile-content-section-atribute-info-data">
                    {profileState.nombre}
                  </span>
                </div>
              </Paper>
              <Paper elevation={2} className="profile-content-section-atribute">
                <PersonIcon className="profile-content-section-atribute-icon" />
                <div className="profile-content-section-atribute-info">
                  <span className="profile-content-section-atribute-info-name">Apellido</span>
                  <span className="profile-content-section-atribute-info-data">
                    {profileState.apellido}
                  </span>
                </div>
              </Paper>
              <Paper elevation={2} className="profile-content-section-atribute">
                <PersonIcon className="profile-content-section-atribute-icon" />
                <div className="profile-content-section-atribute-info">
                  <span className="profile-content-section-atribute-info-name">Nombre de Usuario</span>
                  <span className="profile-content-section-atribute-info-data">
                    {profileState.username}
                  </span>
                </div>
              </Paper>
              <Paper elevation={2} className="profile-content-section-atribute">
                <AlternateEmailIcon className="profile-content-section-atribute-icon" />
                <div className="profile-content-section-atribute-info">
                  <span className="profile-content-section-atribute-info-name">Correo Electrónico</span>
                  <span className="profile-content-section-atribute-info-data">
                    {profileState.email}
                  </span>
                </div>
              </Paper>
              <Paper elevation={2} className="profile-content-section-atribute withButton">
                <DriveFileRenameOutlineIcon className="profile-content-section-atribute-icon" />
                <div className="profile-content-section-atribute-info">
                  <span className="profile-content-section-atribute-info-name">Firma</span>
                  {signature && (
                    <img
                      src={signature}
                      alt="Firma"
                      className="signature-preview"
                    />
                  )}
                </div>
                <CreateIcon
                  onClick={handleOpenSign}
                  className="profile-content-section-atribute-edit"
                />
              </Paper>
              <UploadSignature
                open={openSign}
                handleClose={handleCloseSign}
                onSignatureSave={(newSignature) => setSignature(newSignature)}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="profile-content-section">
              <span className="profile-content-section-title">Configuración de la Cuenta</span>
              <Paper elevation={2} className="profile-content-section-atribute">
                <ExtensionIcon className="profile-content-section-atribute-icon" />
                <div className="profile-content-section-atribute-info">
                  <span className="profile-content-section-atribute-info-name">Rol</span>
                  <span className="profile-content-section-atribute-info-data">
                    {profileState.rol}
                  </span>
                </div>
              </Paper>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="profile-content-section">
              <span className="profile-content-section-title">Seguridad</span>
              <Paper elevation={2} className="profile-content-section-atribute withButton">
                <VpnKeyIcon className="profile-content-section-atribute-icon" />
                <div className="profile-content-section-atribute-info">
                  <span className="profile-content-section-atribute-info-name">Contraseña</span>
                  <span type="password" className="profile-content-section-atribute-info-data">
                    ••••••••••
                  </span>
                </div>
                <CreateIcon
                  onClick={handleOpenPW}
                  className="profile-content-section-atribute-edit"
                />
              </Paper>
              <Modal
                open={openPW}
                onClose={handleClosePW}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div className="modal-title" variant="h6" component="h2">
                    <span>Cambiar Contraseña</span>
                  </div>
                  <form className="form">
                    <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">
                        Contraseña Actual
                      </InputLabel>
                      <OutlinedInput
                        className="form-group-text-field"
                        id="outlined-adornment-cp"
                        type={valuesPW.showPasswordCP ? 'text' : 'password'}
                        value={valuesPW.currentPassword}
                        onChange={handleChange('currentPassword')}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPasswordCP}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {valuesPW.showPasswordCP ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Contraseña Actual"
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">
                        Nueva Contraseña
                      </InputLabel>
                      <OutlinedInput
                        className="form-group-text-field"
                        id="outlined-adornment-np"
                        type={valuesPW.showPasswordNP ? 'text' : 'password'}
                        value={valuesPW.newPassword}
                        onChange={handleChange('newPassword')}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPasswordNP}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {valuesPW.showPasswordNP ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Nueva Contraseña"
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">
                        Repetir Nueva Contraseña
                      </InputLabel>
                      <OutlinedInput
                        className="form-group-text-field"
                        id="outlined-adornment-npr"
                        type={valuesPW.showPasswordNPR ? 'text' : 'password'}
                        value={valuesPW.newPasswordRepeat}
                        onChange={handleChange('newPasswordRepeat')}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPasswordNPR}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {valuesPW.showPasswordNPR ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Repetir Nueva Contraseña"
                      />
                    </FormControl>
                  </form>
                  <Button
                    onClick={onClick}
                    className="change-button"
                    variant="contained"
                    endIcon={<Send />}
                  >
                    Cambiar
                  </Button>
                </Box>
              </Modal>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Profile;
