import React , {useState,createContext,useEffect, useContext} from 'react';

export const Enviroments = createContext();

export const EnviromentsProvider = props => {
    const [enviroment, setEnviroment] = useState('https://backend-dnaut.gentlemoss-f737cdc5.brazilsouth.azurecontainerapps.io');
    const url = window.location.href.split('/');
    const [currentOlt, setCurrentOlt] = useState('none')

    const value = [enviroment, currentOlt]
    return (
      <Enviroments.Provider value={value}>
        {props.children}
      </Enviroments.Provider>
    )
  }
