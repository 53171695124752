import React, { useContext, useEffect, useState } from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import { Enviroments } from '../../../context/Enviroments';


const Test = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [enviroment] = useContext(Enviroments);

    useEffect(() => {

      /* axios.get('https://fastapi-test.gentlesky-4c5141a4.eastus.azurecontainerapps.io/test/') 
            .then((response) => {
                console.log("AAAAAAAAAAAA")
                console.log(response)
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });  */
        
        const data_guardar = 
            {
                "PartitionKey": "Users",
                "RowKey": "luciano",
                "Nombre": "Luciano",
                "Apellido": "San Martin",
                "Password": "pass123",
                "Rol": "viewer",
                "Id": 1,
                "Email": "luciano.test@hola.cl"
            
            }
            
        axios.post(enviroment+'/create-user/', data_guardar) 
            .then((response) => {
                console.log("AAAAAAAAAAAA")
                console.log(response)
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });  
        
        const data_get = 
            {
                "User": "luciano@hola.cl",
                "Password": "pass1234"
            }
        
        /* axios.post(enviroment+'/login-user/', data_get) 
            .then((response) => {
                console.log("GEEEEEEEEEEEET")
                console.log(response)
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });  */
    }, []);

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Vista de Datos
            </Typography>
            {loading ? (
                <CircularProgress />
            ) : (
                <Typography variant="body1">{data ? data.title : 'No hay datos'}</Typography>
            )}
        </Box>
    );
};

export default Test;