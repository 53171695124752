import React, { useState, createContext, useEffect, useContext, useRef } from 'react';
import gestion_acceso from "./gestion_acceso.scss"
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Store } from 'react-notifications-component';
import axios from 'axios';
import html2canvas from 'html2canvas';
import { Menu, 
    CircularProgress,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableSortLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    IconButton,
    TableContainer,
    Paper,
    TablePagination} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import Skeleton from '@mui/material/Skeleton';
import CancelIcon from '@mui/icons-material/Cancel';
import { Enviroments } from '../../../context/Enviroments';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InsightsIcon from '@mui/icons-material/Insights';
import MenuItem from '@mui/material/MenuItem';
import HC_more from 'highcharts/highcharts-more';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import highcharts3d from "highcharts/highcharts-3d";
import Backdrop from '@mui/material/Backdrop';
import { height, styled, width } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material';

const theme = createTheme({
    components: {
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '& > *': {
                        padding: '10px !important', // Cambia este valor según tus necesidades
                    },
                },
            },
        },
    },
});

const TableWrapper = styled('div')(({ theme }) => ({
    overflowX: 'auto',
}));

const CellContent = styled('div')({
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const Gestion_acceso = () => {

    // Load Highcharts modules
    require('highcharts/indicators/indicators')(Highcharts)
    require('highcharts/indicators/pivot-points')(Highcharts)
    require('highcharts/indicators/macd')(Highcharts)
    require('highcharts/modules/exporting')(Highcharts)
    require('highcharts/modules/export-data')(Highcharts)
    require('highcharts/modules/map')(Highcharts)
    //high charts
    const [dataBody, setDataBody] = useState(new Array())
    const [dataHead, setDataHead] = useState(new Array())
    const [dataHeadRechazosPC, setDataHeadRechazosPC] = useState(new Array())
    
    const [dataBody2, setDataBody2] = useState(new Array())

    const [dataBodyRechazosPC, setDataBodyRechazosPC] = useState(new Array())

    const [myFiles, setMyFiles] = React.useState([])

    const [currentFile, setCurrentFile] = React.useState();
    const [File, setFile] = React.useState();

    const [loadingUpload, setLoadingUpload] = useState(null)
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState(dataHead?.[0] || '');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [page2, setPage2] = React.useState(0);
    const [rowsPerPage2, setRowsPerPage2] = React.useState(10);
    
    const [pageRechazosPC, setPageRechazosPC] = React.useState(0);
    const [rowsPerPageRechazosPC, setRowsPerPageRechazosPC] = React.useState(10);

    const [enviroment] = React.useContext(Enviroments);

    const [anchorElCargarData, setAnchorElCargarData] = useState(null);
    const [anchorElEliminarSemana, setAnchorElEliminarSemana] = useState(null);
    const [loading, setLoading] = useState(false)
    const [loadingData, setLoadingData] = React.useState(false)
    const [openUploadCargarData, setOpenUploadCargarData] = useState(false);

    const [anchorElCargarDataSitios, setAnchorElCargarDataSitios] = useState(null);
    const [openUploadCargarDataSitios, setOpenUploadCargarDataSitios] = useState(false);

    // SEMANAS
    const [semanas, setSemanas] = useState(new Array())
    const [ultimaSemana, setUltimaSemana] = useState('')

    // GRAFICO PRIMERO
    const [primeroAprobados, setPrimeroAprobados] = useState(new Array())
    const [primeroSpc, setPrimeroSpc] = useState(new Array())
    const [primeroCpc, setPrimeroCpc] = useState(new Array())
    const [primeroPercSpc, setPrimeroPercSpc] = useState(new Array())
    const [primeroPercCpc, setPrimeroPercCpc] = useState(new Array())

    // GRAFICO ICETEL
    const [icetelAprobados, setIcetelAprobados] = useState(new Array())
    const [icetelRechazados, setIcetelRechazados] = useState(new Array())
    const [icetelPerc, setIcetelPerc] = useState(new Array())

    // GRAFICO ICETEL_ZONA
    const [icetelZonaNorte, setIcetelZonaNorte] = useState(new Array())
    const [icetelZonaRm, setIcetelZonaRm] = useState(new Array())
    const [icetelZonaSur, setIcetelZonaSur] = useState(new Array())

    // GRAFICO TELRAD
    const [telradAprobados, setTelradAprobados] = useState(new Array())
    const [telradRechazados, setTelradRechazados] = useState(new Array())
    const [telradPerc, setTelradPerc] = useState(new Array())

    // GRAFICO TELRAD_ZONA
    const [telradZonaNorte, setTelradZonaNorte] = useState(new Array())
    const [telradZonaRm, setTelradZonaRm] = useState(new Array())
    const [telradZonaSur, setTelradZonaSur] = useState(new Array())

    // GRAFICO CLAROVTR
    const [clarovtrAprobados, setClarovtrAprobados] = useState(new Array())
    const [clarovtrRechazados, setClarovtrRechazados] = useState(new Array())
    const [clarovtrPerc, setClarovtrPerc] = useState(new Array())

    // GRAFICO CLAROVTR_ZONA
    const [clarovtrZonaNorte, setClarovtrZonaNorte] = useState(new Array())
    const [clarovtrZonaRm, setClarovtrZonaRm] = useState(new Array())
    const [clarovtrZonaSur, setClarovtrZonaSur] = useState(new Array())

    // GRAFICO DETALLE_RECHAZOS
    const [detallerechazos, setDetallerechazos] = useState(new Array())

    // GRAFICO TTS
    const [tss, setTss] = useState(new Array())
    const [coloresTss, setColoresTss] = useState(new Array())

    // GRAFICO PROBLEMAS_CONTRACTUALES
    const [problemasContractuales, setProblemasContractuales] = useState(new Array())

    // GRAFICO SITIOS RECHAZOS PC
    const [sitiosRechazosPC, setSitiosRechazosPC] = useState(new Array())
    const [cantidadRechazosPC, setCantidadRechazosPC] = useState(new Array())
 

    const alert_success = (message) => {
        Store.addNotification({
            title: message,
            message: " ",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 7000,
                onScreen: true
            }
        });
    }

    const alert_error = (message) => {
        Store.addNotification({
            title: message,
            message: " ",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                onScreen: true
            }
        });
    }

    // Referencia a la tabla
    const tableRef = useRef(null);
    const tableRef2 = useRef(null);
    const tableRefRechazosPC = useRef(null);

    const primeroRef = useRef(null);
    const icetelRef = useRef(null);
    const icetelZonaRef = useRef(null);
    const telradRef = useRef(null);
    const telradZonaRef = useRef(null);
    const clarovtrRef = useRef(null);
    const clarovtrZonaRef = useRef(null);
    const rechazosRef = useRef(null);
    const pcRef = useRef(null);
    const recurrenciasRef = useRef(null);


    // Función para capturar la tabla como PNG
    const handleDownloadPNG = () => {
        const tableElement = tableRef.current;
        html2canvas(tableElement,
            {
                scale: 12
            }
        ).then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'PC_penultima_semana.png';
        link.click();
        });
    };

    const handleDownloadPNG2 = () => {
        const tableElement = tableRef2.current;
        html2canvas(tableElement,
            {
                scale: 12
            }).then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'PC_ultima_semana.png';
        link.click();
        });
    };

    const handleDownloadPNGRechazosPC = () => {
        const tableElement = tableRefRechazosPC.current;
        html2canvas(tableElement,
            {
                scale: 12
            }).then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'sitios_rechazosPC.png';
        link.click();
        });
    };

    const handleDownloadPrimero = () => {
        const tableElement = primeroRef.current;
        html2canvas(tableElement, {
            scale: 12
        }).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png', 12); // Asegúrate de usar calidad máxima
            link.download = 'solicitudes_aprobadas_rechazadas.png';
            link.click();
        });
    };

    const handleDownloadIcetel = () => {
        const tableElement = icetelRef.current;
        html2canvas(tableElement, {
            scale: 12 // Aumenta el factor de escala para mejorar la resolución
        }).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png', 1.0); // Asegúrate de usar calidad máxima
            link.download = 'icetel.png';
            link.click();
        });
    };

    const handleDownloadIcetelZona = () => {
        const tableElement = icetelZonaRef.current;
        html2canvas(tableElement, {
            scale: 12 // Aumenta el factor de escala para mejorar la resolución
        }).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png', 1.0); // Asegúrate de usar calidad máxima
            link.download = 'icetel_zona.png';
            link.click();
        });
    };

    const handleDownloadTelrad = () => {
        const tableElement = telradRef.current;
        html2canvas(tableElement, {
            scale: 12 // Aumenta el factor de escala para mejorar la resolución
        }).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png', 1.0); // Asegúrate de usar calidad máxima
            link.download = 'telrad.png';
            link.click();
        });
    };

    const handleDownloadTelradZona = () => {
        const tableElement = telradZonaRef.current;
        html2canvas(tableElement, {
            scale: 12 // Aumenta el factor de escala para mejorar la resolución
        }).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png', 1.0); // Asegúrate de usar calidad máxima
            link.download = 'telrad_zona.png';
            link.click();
        });
    };

    const handleDownloadClarovtr = () => {
        const tableElement = clarovtrRef.current;
        html2canvas(tableElement, {
            scale: 12 // Aumenta el factor de escala para mejorar la resolución
        }).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png', 1.0); // Asegúrate de usar calidad máxima
            link.download = 'clarovtr.png';
            link.click();
        });
    };

    const handleDownloadClarovtrZona = () => {
        const tableElement = clarovtrZonaRef.current;
        html2canvas(tableElement, {
            scale: 12 // Aumenta el factor de escala para mejorar la resolución
        }).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png', 1.0); // Asegúrate de usar calidad máxima
            link.download = 'clarovtr_zona.png';
            link.click();
        });
    };

    const handleDownloadRechazos = () => {
        const tableElement = rechazosRef.current;
        html2canvas(tableElement, {
            scale: 12 // Aumenta el factor de escala para mejorar la resolución
        }).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png', 1.0); // Asegúrate de usar calidad máxima
            link.download = 'rechazos.png';
            link.click();
        });
    };

    const handleDownloadPc = () => {
        const tableElement = pcRef.current;
        html2canvas(tableElement, {
            scale: 12 // Aumenta el factor de escala para mejorar la resolución
        }).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png', 1.0); // Asegúrate de usar calidad máxima
            link.download = 'problemas_contractuales.png';
            link.click();
        });
    };

    const handleDownloadRecurrencias = () => {
        const tableElement = recurrenciasRef.current;
        html2canvas(tableElement, {
            scale: 12 // Aumenta el factor de escala para mejorar la resolución
        }).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png', 1.0); // Asegúrate de usar calidad máxima
            link.download = 'recurrencias.png';
            link.click();
        });
    };

    const { acceptedFiles, getRootProps: getRootProps, getInputProps: getInputProps } = useDropzone({
        maxFiles: 1, accept: ['.xlsx'], onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0];
            setCurrentFile(file)
            setFile(file)
            setMyFiles(acceptedFiles)
        }
    });

    const files = myFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size / 1000} Kb <CancelIcon style={{ cursor: 'pointer', color: '#4C6C9C' }} onClick={() => removeFile(file)} />
        </li>

    ));

    useEffect(() => {
        if (dataHead && dataHead.length > 0) {
            setOrderBy(dataHead[0]);
        }
    }, [dataHead]);

    useEffect(() => {
        getDataGraph()
    }, [])

    if (!dataHead || !dataBody || !dataBody2 || !dataBodyRechazosPC) {
        return <CircularProgress />;
    }

    const handleClickCargarData = (event) => {
        setAnchorElCargarData(event.currentTarget);
    };

    const handleClickCargarDataSitios = (event) => {
        setAnchorElCargarDataSitios(event.currentTarget);
    };

    const handleClickEliminarSemana = (event) => {
        setAnchorElEliminarSemana(event.currentTarget);
    };


    const handleCloseCargarData = () => {
        setAnchorElCargarData(null);
    };

    const handleCloseCargarDataSitios = () => {
        setAnchorElCargarDataSitios(null);
    };

    const handleCloseDialogCargarData = () => {
        setOpenUploadCargarData(false);
    };

    const handleCloseDialogCargarDataSitios = () => {
        setOpenUploadCargarDataSitios(false);
    };

    const handleOpenCargarData = () => {
        setMyFiles([])
        setOpenUploadCargarData(true);
        handleCloseCargarData();
    };

    const handleOpenCargarDataSitios = () => {
        setMyFiles([])
        setOpenUploadCargarDataSitios(true);
        handleCloseCargarDataSitios();
    };

    const handleCloseEliminarSemana = () => {
        setAnchorElEliminarSemana(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const removeFile = (file) => {
        setMyFiles([])
        setCurrentFile()
    }

    async function getDataGraph() {
        setLoading(true)

        let token = localStorage.getItem('token');
        const headers = {
            "Accept": 'application/json',
            "Authorization": `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*',
        };

        await axios(enviroment + "/get-data-graph-gestion-acceso/", { headers })
            .then(response => {

                // SEMANAS
                setSemanas(response.data['semanas'])
                setUltimaSemana((response.data['semanas'][response.data['semanas'].length - 1]).toString())

                // PRIMERO
                setPrimeroAprobados(response.data['primero']['aprobados'])
                setPrimeroSpc(response.data['primero']['spc'])
                setPrimeroCpc(response.data['primero']['cpc'])
                setPrimeroPercSpc(response.data['primero']['perc_spc'])
                setPrimeroPercCpc(response.data['primero']['perc_cpc'])

                // ICETEL
                setIcetelAprobados(response.data['icetel']['aprobados'])
                setIcetelRechazados(response.data['icetel']['rechazados'])
                setIcetelPerc(response.data['icetel']['perc'])

                // ICETEL_ZONA
                setIcetelZonaNorte(response.data['icetel_zona'][response.data['icetel_zona'].length -1]['norte'])
                setIcetelZonaRm(response.data['icetel_zona'][response.data['icetel_zona'].length -1]['rm'])
                setIcetelZonaSur(response.data['icetel_zona'][response.data['icetel_zona'].length -1]['sur'])

                // TELRAD
                setTelradAprobados(response.data['telrad']['aprobados'])
                setTelradRechazados(response.data['telrad']['rechazados'])
                setTelradPerc(response.data['telrad']['perc'])

                // TELRAD_ZONA
                setTelradZonaNorte(response.data['telrad_zona'][response.data['telrad_zona'].length -1]['norte'])
                setTelradZonaRm(response.data['telrad_zona'][response.data['telrad_zona'].length -1]['rm'])
                setTelradZonaSur(response.data['telrad_zona'][response.data['telrad_zona'].length -1]['sur'])

                // CLAROVTR
                setClarovtrAprobados(response.data['clarovtr']['aprobados'])
                setClarovtrRechazados(response.data['clarovtr']['rechazados'])
                setClarovtrPerc(response.data['clarovtr']['perc'])

                // CLAROVTR_ZONA
                setClarovtrZonaNorte(response.data['clarovtr_zona'][response.data['clarovtr_zona'].length -1]['norte'])
                setClarovtrZonaRm(response.data['clarovtr_zona'][response.data['clarovtr_zona'].length -1]['rm'])
                setClarovtrZonaSur(response.data['clarovtr_zona'][response.data['clarovtr_zona'].length -1]['sur'])

                // DETALLE_RECHAZOS

                const dict_detalle_rechazos = response.data['detalle_rechazos'][response.data['detalle_rechazos'].length -1]
                let lista_detalle_rechazos = []
                
                let dict_tipos_rechazos = {
                    "PROBLEMA CONTRACTUAL": '#4472C4',  // Azul
                    "DUPLICADO": '#ED7D31',            // Naranja
                    "NORMATIVA": '#70AD47',            // Verde
                    "CONDICIONES CLIMATICAS": '#FFC000',  // Amarillo
                    "DOCUMENTACIÓN O INFORMACIÓN": '#A5A5A5',  // Gris
                    "PLATAFORMA CON PROBLEMAS": '#5B9BD5',  // Azul Claro
                    "POR DEFECTO": '#C00000',           // Rojo Oscuro
                    "POR HORARIO": '#7030A0'            // Púrpura
                };

                Object.keys(dict_detalle_rechazos).forEach(clave => {
                    
                    let dict_aux = { 
                        name: clave,
                        type: 'column',
                        color: dict_tipos_rechazos[clave], // Azul oscuro
                        data: response.data['detalle_rechazos'][response.data['detalle_rechazos'].length -1][clave],
                        tooltip: {
                            valueSuffix: ' solicitudes'
                        },
                        dataLabels: {
                            allowOverlap: true,
                            enabled: true,
                            color: 'black',
                            inside: false,
                            padding: 0,
                            style: {
                                fontWeight: 'bold',
                                fontSize: '9px'
                            }
                        }
                    }

                    lista_detalle_rechazos.push(dict_aux)
                })
                
                setDetallerechazos(lista_detalle_rechazos)

                // TSS
                setTss(response.data['tss'][response.data['tss'].length -1])
                setColoresTss(['#4472C4', '#ED7D31'])
                
                // PROBLEMAS_CONTRACTUALES
                setProblemasContractuales(response.data['problemas_contractuales']['problemas_contractuales'])
                setDataHead(['Semana', 'Estado', 'Codigo Sitio', 'Nombre Sitio', 'Solicitudes'])
                let listas_sites = response.data['problemas_contractuales']['lista_sites']
                let lista1 = [];
                let lista2 = [];

                if (listas_sites.length === 0) {
                    // Caso 1: Si no hay listas, retorna una lista vacía
                    lista1 = [];
                    lista2 = [];
                } else if (listas_sites.length === 1) {
                    // Caso 2: Si solo hay una lista, retorna esa lista
                    lista1 = [];
                    lista2 = listas_sites[0];
                } else {
                    // Caso 3: Si hay dos o más listas, concatena la penúltima y la última
                    lista1 = listas_sites[listas_sites.length - 2];
                    lista2 = listas_sites[listas_sites.length - 1];
                }
                setDataBody(lista1)
                setDataBody2(lista2)
                setPage(0)
                setPage2(0)

                // SITIOS CANTIDAD RECHAZOS PC
                setSitiosRechazosPC(response.data['sitios_pc']['sitios'])
                setCantidadRechazosPC(response.data['sitios_pc']['valores'])
                setDataBodyRechazosPC(response.data['sitios_pc']['tabla'])
                setDataHeadRechazosPC(['Sitio', 'Recurrencia rechazos PC', 'Semanas'])
                setPageRechazosPC(0)

                setLoading(false)
                alert_success("Gráficos generados correctamente")
            }).catch(error => {
                setLoading(false)
                alert_error("Error al generar gráficos")
            })
    }

    async function eliminar_semana(semana_eliminar) {
        setLoading(true)
        handleCloseEliminarSemana()
        
        let token = localStorage.getItem('token');
        const headers = {
            "Accept": 'application/json',
            "Authorization": `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*',
        };

        await axios(enviroment + "/eliminar-semana-gestion-acceso/"+semana_eliminar.toString(), { headers })
            .then(response => {
                setLoading(false)
                getDataGraph()
                alert_success("Semana eliminada correctamente")
            }).catch(error => {
                setLoading(false)
                alert_error("Error al eliminar semana")
            })
    }

    async function uploadDataExcelGestiones() {

        let token = localStorage.getItem('token');
        const headers = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*',
        };

        setLoading(true)
        const reader = new FileReader();
      
        reader.onload = async () => {
        try {
            console.log(myFiles[0])
          const response = await axios.post(enviroment + "/upload-excel-gestion-acceso/"+myFiles[0].name, reader.result, {
            headers: {
              'Content-Type': myFiles[0].type, 
              "Accept": 'application/json',
              "Authorization": `Bearer ${token}`,
              'Access-Control-Allow-Origin': '*',
            },
          });
          
          if (response.status === 200) {
            setMyFiles([])
            
            setOpenUploadCargarData(false);
            setLoading(false)
            alert_success("Excel cargado correctamente")
            await getDataGraph()

          } else {
            setOpenUploadCargarData(false);
            setLoading(false)
            alert_error("Error al cargar el excel")
          }
        } catch (error) {
            setOpenUploadCargarData(false);
            setLoading(false)
            alert_error("Error al cargar el excel")
        }
      };
      
      reader.readAsArrayBuffer(myFiles[0]);
    }

    
    async function uploadDataExcelSitios() {

        let token = localStorage.getItem("token")
        const headers = {
            "Accept": "application/json",
        };

        setLoading(true)
        const reader = new FileReader();
      
        reader.onload = async () => {
        try {
            console.log(myFiles[0])
          const response = await axios.post(enviroment + "/upload-excel-sitios-gestion-acceso/"+myFiles[0].name, reader.result, {
            headers: {
              'Content-Type': myFiles[0].type, 
              "Accept": 'application/json',
              "Authorization": `Bearer ${token}`,
              'Access-Control-Allow-Origin': '*',
            },
          });
          
          if (response.status === 200) {
            setMyFiles([])
            
            setOpenUploadCargarData(false);
            setLoading(false)
            alert_success("Excel cargado correctamente")
            await getDataGraph()

          } else {
            setOpenUploadCargarData(false);
            setLoading(false)
            alert_error("Error al cargar el excel")
          }
        } catch (error) {
            setOpenUploadCargarData(false);
            setLoading(false)
            alert_error("Error al cargar el excel")
        }
      };
      
      reader.readAsArrayBuffer(myFiles[0]);
    }


    HC_more(Highcharts);

    const PRIMERO = {
        chart: {
            zooming: {
                type: 'xy'
            },
            marginBottom: 100,
            height: 700
        },
        title: {
            text: 'Solicitudes aprobadas vs rechazadas (Con/Sin Problemas Contractuales)',
            align: 'center',
            style: {
                fontSize: '16px',
                fontWeight: 'bold'
            }
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false // Desactiva el botón de exportación
        },
        xAxis: [{
            categories: semanas,
            crosshair: true,
            title: {
                text: 'Week',
                style: {
                    color: 'black',
                    fontWeight: 'bold'
                }
            },
        }],
        yAxis: [{ // Eje Y izquierdo (SOLICITUDES)
            labels: {
                format: '{value}',
                style: {
                    color: 'black'
                },
                formatter: function () {
                    return this.value <= 350 ? this.value : '';
                }
            },
            title: {
                text: '',
                style: {
                    color: 'black'
                }
            },
            tickPositions: [0, 50, 100, 150, 200, 250, 300, 350, 400],
            max: 460
        }, { // Eje Y derecho (PORCENTAJE)
            title: {
                text: 'PORCENTAJE',
                style: {
                    color: 'white'
                }
            },
            labels: {
                format: '{value}%',
                style: {
                    color: 'black'
                },
                formatter: function () {
                    return this.value <= 100 ? this.value + '%' : '';
                }
            },
            opposite: true,
            tickPositions: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110],
            max: 120,
            plotLines: [{
                color: 'red',
                value: 90,
                width: 2,
                dashStyle: 'ShortDash',
                label: {
                    text: 'Target',
                    align: 'right',
                    x: 25,
                    y: -5,
                    style: {
                        color: 'red',
                        fontWeight: 'bold'
                    }
                }
            }]
        }],
        tooltip: {
            shared: true
        },
        legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal',
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'rgba(255,255,255,0.25)'
        },
        plotOptions: {
            series: {
                dataLabels: {
                    allowOverlap: true,
                    padding: 0,
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        textOutline: 'none'
                    },
                    formatter: function() {
                        return this.y;
                    }
                }
            }
        },
        series: [
            { // Primera barra (APROBADO)
                name: 'APROBADO',
                type: 'column',
                color: '#4472C4', // Azul oscuro
                data: primeroAprobados,
                tooltip: {
                    valueSuffix: ' solicitudes'
                },
                dataLabels: {
                    allowOverlap: true,
                    enabled: true,
                    color: 'black',
                    inside: false,
                    padding: 0,
                    style: {
                        fontWeight: 'bold',
                        fontSize: '9px'
                    }
                }
            },
            { // Segunda barra (RECHAZ./S_P_C)
                name: 'RECHAZ./S_P_C',
                type: 'column',
                color: '#ED7D31', // Naranja potente
                data: primeroSpc,
                tooltip: {
                    valueSuffix: ' solicitudes'
                },
                dataLabels: {
                    allowOverlap: true,
                    padding: 0,
                    enabled: true,
                    color: 'black',
                    inside: false,
                    style: {
                        fontWeight: 'bold',
                        fontSize: '9px'
                    }
                }
            },
            { // Tercera barra (RECHAZ./C_P_C)
                name: 'RECHAZ./C_P_C',
                type: 'column',
                color: '#A5A5A5', // Gris oscuro
                data: primeroCpc,
                tooltip: {
                    valueSuffix: ' solicitudes'
                },
                dataLabels: {
                    allowOverlap: true,
                    enabled: true,
                    color: '#FF0000',
                    inside: false,
                    padding: 0,
                    style: {
                        fontWeight: 'bold',
                        fontSize: '9px'
                    }
                }
            },
            { // Primera línea (Porcentaje S_P_C)
                name: '% S_P_C',
                type: 'spline',
                yAxis: 1,
                color: '#32CD32', // Verde claro
                data: primeroPercSpc,
                tooltip: {
                    valueSuffix: '%'
                },
                dataLabels: {
                    allowOverlap: true,
                    enabled: true,
                    color: '#4472C4',
                    format: '{y}%',
                    verticalAlign: 'bottom',
                    padding: 0,
                    style: {
                        fontWeight: 'bold',
                        fontSize: '9px'
                    }
                }
            },
            { // Segunda línea (Porcentaje C_P_C)
                name: '% C_P_C',
                type: 'spline',
                yAxis: 1,
                color: '#FF0000', // Roja
                data: primeroPercCpc,
                tooltip: {
                    valueSuffix: '%'
                },
                dataLabels: {
                    allowOverlap: true,
                    enabled: true,
                    color: 'black',
                    format: '{y}%',
                    verticalAlign: 'top',
                    padding: 0,
                    style: {
                        fontWeight: 'bold',
                        fontSize: '9px'
                    }
                }
            }
        ]
    };
    
    const ICETEL = {
        chart: {
            zooming: {
                type: 'xy'
            },
            marginBottom: 100,
            height: 500
        },
        title: {
            text: 'ICETEL',
            align: 'center',
            style: {
                fontSize: '16px',
                fontWeight: 'bold'
            }
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false // Desactiva el botón de exportación
        },
        xAxis: [{
            categories: semanas,
            crosshair: true,
            title: {
                text: 'Week',
                style: {
                    color: 'black',
                    fontWeight: 'bold'
                }
            },
        }],
        yAxis: [{ // Eje Y izquierdo (SOLICITUDES)
            labels: {
                format: '{value}',
                style: {
                    color: 'black'
                },
                formatter: function () {
                    return this.value <= 120 ? this.value : '';
                }
            },
            title: {
                text: '',
                style: {
                    color: 'black'
                }
            },
            tickPositions: [0, 20, 40, 60, 80, 100, 120, 140],
            max: 460
        }, { // Eje Y derecho (PORCENTAJE)
            title: {
                text: 'PORCENTAJE',
                style: {
                    color: 'white'
                }
            },
            labels: {
                format: '{value}%',
                style: {
                    color: 'black'
                },
                formatter: function () {
                    return this.value <= 100 ? this.value + '%' : '';
                }
            },
            opposite: true,
            tickPositions: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110],
            max: 120,
        }],
        tooltip: {
            shared: true
        },
        legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal',
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'rgba(255,255,255,0.25)'
        },
        plotOptions: {
            series: {
                dataLabels: {
                    allowOverlap: true,
                    padding: 0,
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        textOutline: 'none'
                    },
                    formatter: function() {
                        return this.y;
                    }
                }
            }
        },
        series: [
            { // Primera barra (APROBADO)
                name: 'APROBADO',
                type: 'column',
                color: '#4472C4', // Azul oscuro
                data: icetelAprobados,
                tooltip: {
                    valueSuffix: ' solicitudes'
                },
                dataLabels: {
                    allowOverlap: true,
                    enabled: true,
                    color: 'black',
                    inside: false,
                    padding: 0,
                    style: {
                        fontWeight: 'bold',
                        fontSize: '9px'
                    }
                }
            },
            { // Segunda barra (RECHAZ./S_P_C)
                name: 'RECHAZADO',
                type: 'column',
                color: '#ED7D31', // Naranja potente
                data: icetelRechazados,
                tooltip: {
                    valueSuffix: ' solicitudes'
                },
                dataLabels: {
                    allowOverlap: true,
                    padding: 0,
                    enabled: true,
                    color: 'black',
                    inside: false,
                    style: {
                        fontWeight: 'bold',
                        fontSize: '9px'
                    }
                }
            },
            {
                name: '%',
                type: 'spline',
                yAxis: 1,
                color: '#A5A5A5', 
                data: icetelPerc,
                tooltip: {
                    valueSuffix: '%'
                },
                dataLabels: {
                    allowOverlap: true,
                    enabled: true,
                    color: '#FF0000',
                    format: '{y}%',
                    verticalAlign: 'bottom',
                    padding: 0,
                    style: {
                        fontWeight: 'bold',
                        fontSize: '9px'
                    }
                }
            }
        ]
    };

    const ICETEL_ZONA = {
        chart: {
            type: 'column',
            height: 500
        },
        exporting: {
            enabled: false // Desactiva el botón de exportación
        },
        title: {
            text: 'ICETEL por Zona W' + ultimaSemana
        },
        xAxis: {
            categories: ['APROBADO', 'CONTRACTUAL', 'RECHAZADO']
        },
        yAxis: {
            min: 0,
            tickPositions: [0, 10, 20, 30, 40, 50, 60, 70, 80],
            title: {
                text: ''
            }
        },
        plotOptions: {
            series: {
                stacking: 'normal'
            }
        },
        series: [
            {
                name: 'SUR',
                data: [
                    { y: icetelZonaSur[0], dataLabels: { align: 'center' } }, // Centrar dataLabel para la primera barra
                    icetelZonaSur[1], 
                    icetelZonaSur[2]
                ],
                color:"#46d783",
                dataLabels: {
                    allowOverlap: true,
                    padding: 0,
                    enabled: true,
                    color: 'black',
                    style: {
                        fontWeight: 'bold',
                        fontSize: '13px'
                    }
                }
            },
            {
                name: 'RM',
                data: [
                    { y: icetelZonaRm[0], dataLabels: { align: 'center' } }, // Centrar dataLabel para la primera barra
                    icetelZonaRm[1], 
                    icetelZonaRm[2]
                ],
                color: "#31c1f6",
                dataLabels: {
                    allowOverlap: true,
                    align: 'right',
                    enabled: true,
                    color: 'black',
                    style: {
                        fontWeight: 'bold',
                        fontSize: '13px'
                    }
                }
            },
            {
                name: 'NORTE',
                data: [
                    { y: icetelZonaNorte[0], dataLabels: { align: 'center' } }, // Centrar dataLabel para la primera barra
                    icetelZonaNorte[1], 
                    icetelZonaNorte[2]
                ],
                color: "#f6f63f",
                dataLabels: {
                    allowOverlap: true,
                    padding: 0,
                    enabled: true,
                    align: 'left',
                    color: 'black',
                    style: {
                        fontWeight: 'bold',
                        fontSize: '13px'
                    }
                }
            },
        ]
    };

    const TELRAD = {
        chart: {
            zooming: {
                type: 'xy'
            },
            marginBottom: 100,
            height: 500
        },
        exporting: {
            enabled: false // Desactiva el botón de exportación
        },
        title: {
            text: 'TELRAD',
            align: 'center',
            style: {
                fontSize: '16px',
                fontWeight: 'bold'
            }
        },
        credits: {
            enabled: false
        },
        xAxis: [{
            categories: semanas,
            crosshair: true,
            title: {
                text: 'Week',
                style: {
                    color: 'black',
                    fontWeight: 'bold'
                }
            },
        }],
        yAxis: [{ // Eje Y izquierdo (SOLICITUDES)
            labels: {
                format: '{value}',
                style: {
                    color: 'black'
                },
                formatter: function () {
                    return this.value <= 120 ? this.value : '';
                }
            },
            title: {
                text: '',
                style: {
                    color: 'black'
                }
            },
            tickPositions: [0, 20, 40, 60, 80, 100, 120, 140],
            max: 460
        }, { // Eje Y derecho (PORCENTAJE)
            title: {
                text: 'PORCENTAJE',
                style: {
                    color: 'white'
                }
            },
            labels: {
                format: '{value}%',
                style: {
                    color: 'black'
                },
                formatter: function () {
                    return this.value <= 100 ? this.value + '%' : '';
                }
            },
            opposite: true,
            tickPositions: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110],
            max: 120,
        }],
        tooltip: {
            shared: true
        },
        legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal',
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'rgba(255,255,255,0.25)'
        },
        plotOptions: {
            series: {
                dataLabels: {
                    allowOverlap: true,
                    padding: 0,
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        textOutline: 'none'
                    },
                    formatter: function() {
                        return this.y;
                    }
                }
            }
        },
        series: [
            { // Primera barra (APROBADO)
                name: 'APROBADO',
                type: 'column',
                color: '#4472C4', // Azul oscuro
                data: telradAprobados,
                tooltip: {
                    valueSuffix: ' solicitudes'
                },
                dataLabels: {
                    allowOverlap: true,
                    enabled: true,
                    color: 'black',
                    inside: false,
                    padding: 0,
                    style: {
                        fontWeight: 'bold',
                        fontSize: '9px'
                    }
                }
            },
            { // Segunda barra (RECHAZ./S_P_C)
                name: 'RECHAZADO',
                type: 'column',
                color: '#ED7D31', // Naranja potente
                data: telradRechazados,
                tooltip: {
                    valueSuffix: ' solicitudes'
                },
                dataLabels: {
                    allowOverlap: true,
                    padding: 0,
                    enabled: true,
                    color: 'black',
                    inside: false,
                    style: {
                        fontWeight: 'bold',
                        fontSize: '9px'
                    }
                }
            },
            {
                name: '%',
                type: 'spline',
                yAxis: 1,
                color: '#A5A5A5', 
                data: telradPerc,
                tooltip: {
                    valueSuffix: '%'
                },
                dataLabels: {
                    allowOverlap: true,
                    enabled: true,
                    color: '#FF0000',
                    format: '{y}%',
                    verticalAlign: 'bottom',
                    padding: 0,
                    style: {
                        fontWeight: 'bold',
                        fontSize: '9px'
                    }
                }
            }
        ]
    };
    
    const TELRAD_ZONA = {
        chart: {
            type: 'column'
            ,
            height: 500
        },
        exporting: {
            enabled: false // Desactiva el botón de exportación
        },
        title: {
            text: 'TELRAD por Zona W' + ultimaSemana
        },
        xAxis: {
            categories: ['APROBADO', 'CONTRACTUAL', 'RECHAZADO']
        },
        yAxis: {
            min: 0,
            tickPositions: [0, 10, 20, 30, 40, 50, 60, 70, 80],
            title: {
                text: ''
            }
        },
        plotOptions: {
            series: {
                stacking: 'normal'
            }
        },
        series: [
            {
                name: 'SUR',
                data: [
                    { y: telradZonaSur[0], dataLabels: { align: 'center' } }, // Centrar dataLabel para la primera barra
                    telradZonaSur[1], 
                    telradZonaSur[2]
                ],
                color:"#46d783",
                dataLabels: {
                    allowOverlap: true,
                    padding: 0,
                    enabled: true,
                    color: 'black',
                    style: {
                        fontWeight: 'bold',
                        fontSize: '13px'
                    }
                }
            },
            {
                name: 'RM',
                data: [
                    { y: telradZonaRm[0], dataLabels: { align: 'center' } }, // Centrar dataLabel para la primera barra
                    telradZonaRm[1], 
                    telradZonaRm[2]
                ],
                color: "#31c1f6",
                dataLabels: {
                    allowOverlap: true,
                    align: 'right',
                    enabled: true,
                    color: 'black',
                    style: {
                        fontWeight: 'bold',
                        fontSize: '13px'
                    }
                }
            },
            {
                name: 'NORTE',
                data: [
                    { y: telradZonaNorte[0], dataLabels: { align: 'center' } }, // Centrar dataLabel para la primera barra
                    telradZonaNorte[1], 
                    telradZonaNorte[2]
                ],
                color: "#f6f63f",
                dataLabels: {
                    allowOverlap: true,
                    padding: 0,
                    enabled: true,
                    align: 'left',
                    color: 'black',
                    style: {
                        fontWeight: 'bold',
                        fontSize: '13px'
                    }
                }
            },
        ],
    };

    const CLAROVTR = {
        chart: {
            zooming: {
                type: 'xy'
            },
            marginBottom: 100,
            height: 500
        },
        exporting: {
            enabled: false // Desactiva el botón de exportación
        },
        title: {
            text: 'ClaroVtr',
            align: 'center',
            style: {
                fontSize: '16px',
                fontWeight: 'bold'
            }
        },
        credits: {
            enabled: false
        },
        xAxis: [{
            categories: semanas,
            crosshair: true,
            title: {
                text: 'Week',
                style: {
                    color: 'black',
                    fontWeight: 'bold'
                }
            },
        }],
        yAxis: [{ // Eje Y izquierdo (SOLICITUDES)
            labels: {
                format: '{value}',
                style: {
                    color: 'black'
                },
                formatter: function () {
                    return this.value <= 70 ? this.value : '';
                }
            },
            title: {
                text: '',
                style: {
                    color: 'black'
                }
            },
            tickPositions: [0, 10, 20, 30, 40, 50, 60, 70, 80],
            max: 80
        }, { // Eje Y derecho (PORCENTAJE)
            title: {
                text: 'PORCENTAJE',
                style: {
                    color: 'white'
                }
            },
            labels: {
                format: '{value}%',
                style: {
                    color: 'black'
                },
                formatter: function () {
                    return this.value <= 100 ? this.value + '%' : '';
                }
            },
            opposite: true,
            tickPositions: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110],
            max: 120,
        }],
        tooltip: {
            shared: true
        },
        legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal',
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'rgba(255,255,255,0.25)'
        },
        plotOptions: {
            series: {
                dataLabels: {
                    allowOverlap: true,
                    padding: 0,
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        textOutline: 'none'
                    },
                    formatter: function() {
                        return this.y;
                    }
                }
            }
        },
        series: [
            { // Primera barra (APROBADO)
                name: 'APROBADO',
                type: 'column',
                color: '#4472C4', // Azul oscuro
                data: clarovtrAprobados,
                tooltip: {
                    valueSuffix: ' solicitudes'
                },
                dataLabels: {
                    allowOverlap: true,
                    enabled: true,
                    color: 'black',
                    inside: false,
                    padding: 0,
                    style: {
                        fontWeight: 'bold',
                        fontSize: '9px'
                    }
                }
            },
            { // Segunda barra (RECHAZ./S_P_C)
                name: 'RECHAZADO',
                type: 'column',
                color: '#ED7D31', // Naranja potente
                data: clarovtrRechazados,
                tooltip: {
                    valueSuffix: ' solicitudes'
                },
                dataLabels: {
                    allowOverlap: true,
                    padding: 0,
                    enabled: true,
                    color: 'black',
                    inside: false,
                    style: {
                        fontWeight: 'bold',
                        fontSize: '9px'
                    }
                }
            },
            {
                name: '%',
                type: 'spline',
                yAxis: 1,
                color: '#A5A5A5', 
                data: clarovtrPerc,
                tooltip: {
                    valueSuffix: '%'
                },
                dataLabels: {
                    allowOverlap: true,
                    enabled: true,
                    color: '#FF0000',
                    format: '{y}%',
                    verticalAlign: 'bottom',
                    padding: 0,
                    style: {
                        fontWeight: 'bold',
                        fontSize: '9px'
                    }
                }
            }
        ]
    };
    
    const CLAROVTR_ZONA = {
        chart: {
            type: 'column',
            height: 500         
        },
        exporting: {
            enabled: false // Desactiva el botón de exportación
        },
        title: {
            text: 'ClaroVtr por Zona W' + ultimaSemana
        },
        xAxis: {
            categories: ['APROBADO', 'RECHAZADO']
        },
        yAxis: {
            min: 0,
            tickPositions: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
            title: {
                text: ''
            }
        },
        plotOptions: {
            series: {
                stacking: 'normal'
            }
        },
        series: [
            {
                name: 'SUR',
                data: [
                    { y: clarovtrZonaSur[0], dataLabels: { align: 'center' } }, // Centrar dataLabel para la primera barra
                    clarovtrZonaSur[1]
                ],
                color:"#46d783",
                dataLabels: {
                    allowOverlap: true,
                    padding: 0,
                    enabled: true,
                    color: 'black',
                    style: {
                        fontWeight: 'bold',
                        fontSize: '13px'
                    }
                }
            },
            {
                name: 'RM',
                data: [
                    { y: clarovtrZonaRm[0], dataLabels: { align: 'center' } }, // Centrar dataLabel para la primera barra
                    clarovtrZonaRm[1]
                ],
                color: "#31c1f6",
                dataLabels: {
                    allowOverlap: true,
                    align: 'right',
                    enabled: true,
                    color: 'black',
                    style: {
                        fontWeight: 'bold',
                        fontSize: '13px'
                    }
                }
            },
            {
                name: 'NORTE',
                data: [
                    { y: clarovtrZonaNorte[0], dataLabels: { align: 'center' } }, // Centrar dataLabel para la primera barra
                    clarovtrZonaNorte[1]
                ],
                color: "#f6f63f",
                dataLabels: {
                    allowOverlap: true,
                    padding: 0,
                    enabled: true,
                    align: 'left',
                    color: 'black',
                    style: {
                        fontWeight: 'bold',
                        fontSize: '13px'
                    }
                }
            },
        ]
    
    };

    const DETALLE_RECHAZOS = {
        chart: {
            zooming: {
                type: 'xy'
            },
            marginBottom: 100,
            height: 250 
        },
        exporting: {
            enabled: false // Desactiva el botón de exportación
        },
        title: {
            text: 'Detalle Rechazos W' + ultimaSemana,
            align: 'center',
            style: {
                fontSize: '16px',
                fontWeight: 'bold'
            }
        },
        credits: {
            enabled: false
        },
        xAxis: [{
            categories: [
                'CLARO', 'ICETEL', 'TELRAD'
            ],
            crosshair: true,
            title: {
                text: '',
                style: {
                    color: 'black',
                    fontWeight: 'bold'
                }
            },
        }],
        yAxis: [{ // Eje Y izquierdo (SOLICITUDES)
            labels: {
                format: '{value}',
                style: {
                    color: 'black'
                },
                formatter: function () {
                    return this.value <= 7 ? this.value : '';
                }
            },
            title: {
                text: '',
                style: {
                    color: 'black'
                }
            },
            tickPositions: [0, 1, 2, 3, 4, 5],
            max: 7
        }],
        tooltip: {
            shared: true
        },
        legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal',
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'rgba(255,255,255,0.25)'
        },
        plotOptions: {
            series: {
                dataLabels: {
                    allowOverlap: true,
                    padding: 0,
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        textOutline: 'none'
                    },
                    formatter: function() {
                        return this.y;
                    }
                }
            }
        },
        series: detallerechazos
    };

    highcharts3d(Highcharts);
    
    const VISITA_TTS = {
        chart: {
            type: 'pie',
            options3d: {
                enabled: true,
                alpha: 45,
                beta: 0
            }
        },
        exporting: {
            enabled: false // Desactiva el botón de exportación
        },
        title: {
            text: 'Visita TSS (Preparación y Visitas Subtel) W' + ultimaSemana.toString(),
            align: 'center',
            style: {
                fontSize: '16px',
                fontWeight: 'bold'
            }
        },
        colors: coloresTss,
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.y}</b>' // Muestra el valor correcto
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    format: '{point.y:,.0f}',  // Solo muestra el valor numérico dentro del gráfico
                    enabled: true,
                    inside: true,
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: 'white'
                    }
                },
                depth: 75,
                showInLegend: true  // Muestra los nombres como leyenda
            }
        },
        legend: {
            align: 'center',  // Coloca la leyenda centrada horizontalmente
            verticalAlign: 'bottom',  // Coloca la leyenda en la parte inferior
            layout: 'horizontal',  // Muestra la leyenda de forma horizontal
            itemStyle: {
                fontSize: '13px',
                fontWeight: 'bold'
            }
        },
        series: [{
            type: 'pie',
            name: 'Valor',
            data: tss
        }]
    };


    const SOLICITUDES_PC = {
        chart: {
            zooming: {
                type: 'xy'
            },
            marginBottom: 100,
            height: 300  
        },
        exporting: {
            enabled: false // Desactiva el botón de exportación
        },
        title: {
            text: 'Solicitudes con Problemas Contractuales W' + ultimaSemana,
            align: 'center',
            style: {
                fontSize: '16px',
                fontWeight: 'bold'
            }
        },
    
        yAxis: {
            title: {
                text: ''
            }
        },
    
        xAxis: {
            categories: semanas,
            crosshair: true,
            title: {
                text: 'Week',
                style: {
                    color: 'black',
                    fontWeight: 'bold'
                }
            },
        },
    
        legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal',
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'rgba(255,255,255,0.25)'
        },

    
        series: [{
            color: "#4472C4",
            name: 'SOLICITUDES C/PROBLEMAS CONTRACTUALES',
            data: problemasContractuales,
            dataLabels: {
                allowOverlap: true,
                padding: 10,
                enabled: true,
                color: 'black',
                inside: false,
                style: {
                    fontWeight: 'bold',
                    fontSize: '9px'
                }
            }
        }],
    
    };

    const SITES_PC = {
        chart: {
            zooming: {
                type: 'xy'
            },
            marginBottom: 100,
            height: 300 
        },
        exporting: {
            enabled: false // Desactiva el botón de exportación
        },
        title: {
            text: 'Recurrencias por Sitio rechazos PC',
            align: 'center',
            style: {
                fontSize: '16px',
                fontWeight: 'bold'
            }
        },
        credits: {
            enabled: false
        },
        xAxis: [{
            categories: sitiosRechazosPC,
            crosshair: true,
            title: {
                text: '',
                style: {
                    color: 'black',
                    fontWeight: 'bold'
                }
            },
        }],
        yAxis: [{ // Eje Y izquierdo (SOLICITUDES)
            labels: {
                format: '{value}',
                style: {
                    color: 'black'
                }
            },
            title: {
                text: '',
                style: {
                    color: 'black'
                }
            },
        }],
        tooltip: {
            shared: true
        },
        legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal',
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'rgba(255,255,255,0.25)'
        },
        plotOptions: {
            series: {
                dataLabels: {
                    allowOverlap: true,
                    padding: 0,
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                        textOutline: 'none'
                    },
                    formatter: function() {
                        return this.y;
                    }
                }
            }
        },
        series: [
            { // Segunda barra (RECHAZ./S_P_C)
                name: 'CANTIDAD RECHAZOS PC',
                type: 'column',
                color: '#ED7D31', // Naranja potente
                data: cantidadRechazosPC,
                tooltip: {
                    valueSuffix: ' rechazos'
                },
                dataLabels: {
                    allowOverlap: true,
                    padding: 0,
                    enabled: true,
                    color: 'black',
                    inside: false,
                    style: {
                        fontWeight: 'bold',
                        fontSize: '9px'
                    }
                }
            }
        ]
    };


    return (
        
        <div className="gestion_acceso">
            <ThemeProvider theme={theme}>
            <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                <CircularProgress color="inherit" />
            </Backdrop>

            <div className="gestion_acceso-top">
                <Grid container spacing={2} sx={{ paddingBottom: 2 }}>
                    <Grid item xs={9} >
                        <div style={{ display: 'flex' }} >
                            <span className="gestion_acceso-top-title"><InsightsIcon className='icon' /> Despliegue gráficos Gestión de Acceso W{ultimaSemana}</span>
                        </div>

                    </Grid>

            
                    <Grid item xs={3}>
                            <div style={{ justifyContent: 'flex-end', display: 'flex', gap: '10px' }}>
                                

                                <Button
                                    sx={{ height: 40 }}
                                    onClick={handleClickCargarData}
                                    className="gestion_acceso-content-buttonArea-button"
                                    variant="contained"
                                    >
                                        {loadingData ? 'Procesando...' : 'Cargar data'}
                                </Button>
                                <Button
                                    sx={{ height: 40 }}
                                    onClick={handleClickEliminarSemana}
                                    className="gestion_acceso-content-buttonArea-button"
                                    variant="contained"
                                    disabled={semanas.length == 0}
                                    >
                                    {loadingData ? 'Eliminando semana...' : 'Eliminar semana'}
                                </Button>                          
                            <Menu
                                anchorEl={anchorElCargarData}
                                open={Boolean(anchorElCargarData)}
                                onClose={handleCloseCargarData}
                            >
                                <MenuItem onClick={handleOpenCargarData}>
                                    <UploadFileIcon /> Cargar data gestiones
                                </MenuItem>
                                <MenuItem onClick={handleOpenCargarDataSitios}>
                                    <UploadFileIcon /> Cargar data sitios
                                </MenuItem>
                            </Menu>
                            
                            <Dialog 
                                open={openUploadCargarData} 
                                onClose={handleCloseDialogCargarData} 
                                PaperProps={{ 
                                    style: { 
                                        borderRadius: '12px', 
                                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', 
                                        width: '600px', 
                                        maxWidth: '80%', 
                                        backgroundColor: '#f3ebf7' // Un tono claro basado en #735079
                                    } 
                                }}
                            >
                                <DialogTitle style={{ backgroundColor: '#735079', padding: '24px', fontSize: '24px', fontWeight: 'bold', color: '#ffffff' }}>
                                    Cargar datos de Gestiones semanales
                                </DialogTitle>
                                <DialogContent style={{ padding: '32px', backgroundColor: '#faf5fc' }}> 
                                    <DialogContentText style={{ fontSize: '18px', color: '#735079', marginTop: '16px' }}>
                                        Selecciona un archivo Excel para cargar los datos de las gestiones semanales.
                                    </DialogContentText>

                                    <div style={{ marginTop: '24px' }}>
                                        <span style={{ fontWeight: 'bold', fontSize: '18px', color: '#735079' }}>Subir nuevo archivo de datos:</span>
                                        <section 
                                            {...getRootProps({ className: 'dropzone', id: "dropzone" })} 
                                            style={{
                                                border: '2px dashed #b39cbb', // Un tono suave para el borde
                                                padding: '24px',
                                                borderRadius: '8px',
                                                textAlign: 'center',
                                                marginTop: '24px',
                                                transition: 'border 0.3s ease-in-out',
                                                backgroundColor: '#efe2f3', // Un fondo suave complementario
                                                cursor: 'pointer',
                                                color: '#735079'
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            <p style={{ fontSize: '18px', color: '#735079' }}>Selecciona o arrastra un archivo excel...</p>
                                        </section>
                                        <aside style={{ marginTop: '24px' }}>
                                            <h4 style={{ marginBottom: '8px', fontSize: '18px', color: '#735079' }}>Archivo:</h4>
                                            <ul style={{ listStyle: 'none', padding: 0, fontSize: '16px', color: '#7d658b' }}>{files.length == 0 ? 'Debes seleccionar un archivo' : files}</ul>
                                        </aside>
                                    </div>
                                </DialogContent>
                                <DialogActions style={{ padding: '24px', backgroundColor: '#f3ebf7' }}>
                                    <Button 
                                        disabled={loading} 
                                        onClick={handleCloseDialogCargarData} 
                                        style={{ 
                                            marginRight: '8px', 
                                            backgroundColor: '#d0b6db', // Un tono más claro para el botón Cerrar
                                            color: '#735079', 
                                            padding: '12px 24px', 
                                            fontSize: '16px' 
                                        }}
                                    >
                                        Cerrar
                                    </Button>
                                    <Button 
                                        disabled={loading} 
                                        onClick={uploadDataExcelGestiones} 
                                        style={{ 
                                            backgroundColor: '#735079', 
                                            color: 'white', 
                                            padding: '12px 24px', 
                                            fontSize: '16px' 
                                        }}
                                    >
                                        {loading ? <CircularProgress color="inherit" size={24} /> : "Cargar datos"}
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog 
                                open={openUploadCargarDataSitios} 
                                onClose={handleCloseDialogCargarDataSitios} 
                                PaperProps={{ 
                                    style: { 
                                        borderRadius: '12px', 
                                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', 
                                        width: '600px', 
                                        maxWidth: '80%', 
                                        backgroundColor: '#f3ebf7' // Un tono claro basado en #735079
                                    } 
                                }}
                            >
                                <DialogTitle style={{ backgroundColor: '#735079', padding: '24px', fontSize: '24px', fontWeight: 'bold', color: '#ffffff' }}>
                                    Cargar datos Sitios
                                </DialogTitle>
                                <DialogContent style={{ padding: '32px', backgroundColor: '#faf5fc' }}> 
                                    <DialogContentText style={{ fontSize: '18px', color: '#735079', marginTop: '16px' }}>
                                        Selecciona un archivo Excel para cargar los datos de los sitios.
                                    </DialogContentText>

                                    <div style={{ marginTop: '24px' }}>
                                        <span style={{ fontWeight: 'bold', fontSize: '18px', color: '#735079' }}>Subir nuevo archivo de datos:</span>
                                        <section 
                                            {...getRootProps({ className: 'dropzone', id: "dropzone" })} 
                                            style={{
                                                border: '2px dashed #b39cbb', // Un tono suave para el borde
                                                padding: '24px',
                                                borderRadius: '8px',
                                                textAlign: 'center',
                                                marginTop: '24px',
                                                transition: 'border 0.3s ease-in-out',
                                                backgroundColor: '#efe2f3', // Un fondo suave complementario
                                                cursor: 'pointer',
                                                color: '#735079'
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            <p style={{ fontSize: '18px', color: '#735079' }}>Selecciona o arrastra un archivo excel...</p>
                                        </section>
                                        <aside style={{ marginTop: '24px' }}>
                                            <h4 style={{ marginBottom: '8px', fontSize: '18px', color: '#735079' }}>Archivo:</h4>
                                            <ul style={{ listStyle: 'none', padding: 0, fontSize: '16px', color: '#7d658b' }}>{files.length == 0 ? 'Debes seleccionar un archivo' : files}</ul>
                                        </aside>
                                    </div>
                                </DialogContent>
                                <DialogActions style={{ padding: '24px', backgroundColor: '#f3ebf7' }}>
                                    <Button 
                                        disabled={loading} 
                                        onClick={handleCloseDialogCargarDataSitios} 
                                        style={{ 
                                            marginRight: '8px', 
                                            backgroundColor: '#d0b6db', // Un tono más claro para el botón Cerrar
                                            color: '#735079', 
                                            padding: '12px 24px', 
                                            fontSize: '16px' 
                                        }}
                                    >
                                        Cerrar
                                    </Button>
                                    <Button 
                                        disabled={loading} 
                                        onClick={uploadDataExcelSitios} 
                                        style={{ 
                                            backgroundColor: '#735079', 
                                            color: 'white', 
                                            padding: '12px 24px', 
                                            fontSize: '16px' 
                                        }}
                                    >
                                        {loading ? <CircularProgress color="inherit" size={24} /> : "Cargar datos"}
                                    </Button>
                                </DialogActions>
                            </Dialog>

                        <Menu
                            anchorEl={anchorElEliminarSemana}
                            open={Boolean(anchorElEliminarSemana)}
                            onClose={handleCloseEliminarSemana}
                            PaperProps={{
                                style: {
                                  maxHeight: semanas.length > 2 ? '200px' : 'auto',  // Limita la altura a 200px si hay más de 2 semanas
                                  width: '150px',
                                },
                              }}
                        >
                             {semanas.map((semana, index) => (
                                <MenuItem key={index} onClick={() => eliminar_semana(semana)}>
                                {semana}
                                </MenuItem>
                            ))}
                        </Menu>
                        </div>
                    </Grid>
                    
                   
                </Grid>
            </div>
            <Grid container spacing={2} >

                <Grid item xs={2} >
                </Grid>
                <Grid item xs={8} >
                    <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ marginBottom: '25px', marginTop: '50px' }}>
                            <div className="chart-container" ref={primeroRef}>
                                <HighchartsReact highcharts={Highcharts} options={PRIMERO} ></HighchartsReact>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px', alignItems: 'center' }}>
                                <Button
                                sx={{ height: 40 }}
                                onClick={handleDownloadPrimero}
                                className="gestion_acceso-content-buttonArea-button"
                                variant="contained"
                                >
                                {loadingData ? 'Descargando...' : 'Descargar Gráfico'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={2} >
                </Grid>

                <Grid item xs={8} >
                    <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ marginBottom: '25px', marginTop: '50px' }}>
                            <div className="chart-container" ref={icetelRef}>
                                <HighchartsReact highcharts={Highcharts} options={ICETEL} ></HighchartsReact>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px', alignItems: 'center' }}>
                                <Button
                                sx={{ height: 40 }}
                                onClick={handleDownloadIcetel}
                                className="gestion_acceso-content-buttonArea-button"
                                variant="contained"
                                >
                                {loadingData ? 'Descargando...' : 'Descargar Gráfico'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={4} >
                    <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ marginBottom: '25px', marginTop: '50px' }}>
                            <div className="chart-container" ref={icetelZonaRef}>
                                <HighchartsReact highcharts={Highcharts} options={ICETEL_ZONA} ></HighchartsReact>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px', alignItems: 'center' }}>
                                <Button
                                sx={{ height: 40 }}
                                onClick={handleDownloadIcetelZona}
                                className="gestion_acceso-content-buttonArea-button"
                                variant="contained"
                                >
                                {loadingData ? 'Descargando...' : 'Descargar Gráfico'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={8} >
                    <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ marginBottom: '25px', marginTop: '50px' }}>
                            <div className="chart-container" ref={telradRef}>
                                <HighchartsReact highcharts={Highcharts} options={TELRAD} ></HighchartsReact>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px', alignItems: 'center' }}>
                                <Button
                                sx={{ height: 40 }}
                                onClick={handleDownloadTelrad}
                                className="gestion_acceso-content-buttonArea-button"
                                variant="contained"
                                >
                                {loadingData ? 'Descargando...' : 'Descargar Gráfico'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={4} >
                    <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ marginBottom: '25px', marginTop: '50px' }}>
                            <div className="chart-container" ref={telradZonaRef}>
                                <HighchartsReact highcharts={Highcharts} options={TELRAD_ZONA} ></HighchartsReact>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px', alignItems: 'center' }}>
                                <Button
                                sx={{ height: 40 }}
                                onClick={handleDownloadTelradZona}
                                className="gestion_acceso-content-buttonArea-button"
                                variant="contained"
                                >
                                {loadingData ? 'Descargando...' : 'Descargar Gráfico'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={8} >
                    <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ marginBottom: '25px', marginTop: '50px' }}>
                            <div className="chart-container" ref={clarovtrRef}>
                                <HighchartsReact highcharts={Highcharts} options={CLAROVTR} ></HighchartsReact>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px', alignItems: 'center' }}>
                                <Button
                                sx={{ height: 40 }}
                                onClick={handleDownloadClarovtr}
                                className="gestion_acceso-content-buttonArea-button"
                                variant="contained"
                                >
                                {loadingData ? 'Descargando...' : 'Descargar Gráfico'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={4} >
                    <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ marginBottom: '25px', marginTop: '50px' }}>
                            <div className="chart-container" ref={clarovtrZonaRef}>
                                <HighchartsReact highcharts={Highcharts} options={CLAROVTR_ZONA} ></HighchartsReact>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px', alignItems: 'center' }}>
                                <Button
                                sx={{ height: 40 }}
                                onClick={handleDownloadClarovtrZona}
                                className="gestion_acceso-content-buttonArea-button"
                                variant="contained"
                                >
                                {loadingData ? 'Descargando...' : 'Descargar Gráfico'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={3} >
                </Grid>
                <Grid item xs={6} >
                    <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ marginBottom: '25px', marginTop: '50px' }}>
                            <div className="chart-container" ref={rechazosRef}>
                                <HighchartsReact highcharts={Highcharts} options={DETALLE_RECHAZOS} ></HighchartsReact>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px', alignItems: 'center' }}>
                                <Button
                                sx={{ height: 40 }}
                                onClick={handleDownloadRechazos}
                                className="gestion_acceso-content-buttonArea-button"
                                variant="contained"
                                >
                                {loadingData ? 'Descargando...' : 'Descargar Gráfico'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={3} >
                </Grid>

                {/* <Grid item xs={5} >
                    <div className="chart-container">
                        <HighchartsReact highcharts={Highcharts} options={VISITA_TTS} ></HighchartsReact>
                    </div>
                </Grid> */}

                <Grid item xs={2} >
                </Grid>
                <Grid item xs={8} >
                    <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ marginBottom: '25px', marginTop: '50px' }}>
                            <div className="chart-container" ref={pcRef}>
                                <HighchartsReact highcharts={Highcharts} options={SOLICITUDES_PC} ></HighchartsReact>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px', alignItems: 'center' }}>
                                <Button
                                sx={{ height: 40 }}
                                onClick={handleDownloadPc}
                                className="gestion_acceso-content-buttonArea-button"
                                variant="contained"
                                >
                                {loadingData ? 'Descargando...' : 'Descargar Gráfico'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={2} >
                </Grid>

            </Grid>
            <div style={{ display: 'flex' }}>
                {/* Primera mitad de la pantalla */}
                <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {/* Tu contenido centrado dentro de la primera mitad */}
                    <div style={{ width: '90%', marginBottom: '25px', marginTop: '50px' }}>
                    {dataBody != null ? (
                        <TableWrapper>
                        <TableContainer component={Paper}>
                            <Table stickyHeader ref={tableRef}>
                            <TableHead>
                                <TableRow>
                                {dataHead &&
                                    dataHead.map((label, index) => (
                                    <TableCell
                                        key={label}
                                        align="center"
                                        style={{
                                        fontWeight: 'bold',
                                        maxWidth: '200px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        }}
                                    >
                                        <Tooltip title={label} placement="top">
                                        <span style={{ fontWeight: 'bold' }}>{label}</span>
                                        </Tooltip>
                                    </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataBody
                                .map((row, rowIndex) => (
                                    <TableRow key={rowIndex} sx={{ padding: '8px' }}>
                                    {row.map((cell, cellIndex) => {
                                        if (cellIndex === 1) {
                                        return (
                                            <TableCell
                                            key={cellIndex}
                                            style={{
                                                maxWidth: '200px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                textAlign: 'center',
                                            }}
                                            >
                                            <Tooltip title={cell} placement="top">
                                                {cell === 'RECHAZADO' && <span className="pings-dot red"></span>}
                                                {cell === 'APROBADO' && <span className="pings-dot green"></span>}
                                            </Tooltip>
                                            </TableCell>
                                        );
                                        } else if (cellIndex === 3) {
                                        return (
                                            <TableCell
                                            key={cellIndex}
                                            style={{
                                                maxWidth: '200px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                            >
                                            <Tooltip title={cell} placement="top">
                                                <CellContent>{cell}</CellContent>
                                            </Tooltip>
                                            </TableCell>
                                        );
                                        } else {
                                        return (
                                            <TableCell
                                            key={cellIndex}
                                            style={{
                                                maxWidth: '200px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                textAlign: 'center',
                                            }}
                                            >
                                            <Tooltip title={cell} placement="top">
                                                <CellContent>{cell}</CellContent>
                                            </Tooltip>
                                            </TableCell>
                                        );
                                        }
                                    })}
                                    </TableRow>
                                ))}
                            </TableBody>
                            </Table>
                        </TableContainer>
                        </TableWrapper>
                    ) : (
                        <div></div>
                    )}
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px', alignItems: 'center' }}>
                <Button
                    sx={{ height: 40 }}
                    onClick={handleDownloadPNG}
                    className="gestion_acceso-content-buttonArea-button"
                    variant="contained"
                >
                    {loadingData ? 'Descargando...' : 'Descargar imagen sitios'}
                </Button>
                </div>
                    </div>
                </div>

                {/* Segunda mitad de la pantalla */}
                <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {/* Tu contenido centrado dentro de la primera mitad */}
                    <div style={{ width: '90%', marginBottom: '25px', marginTop: '50px' }}>
                    {dataBody2 != null ? (
                        <TableWrapper>
                        <TableContainer component={Paper}>
                            <Table stickyHeader ref={tableRef2}>
                            <TableHead>
                                <TableRow>
                                {dataHead &&
                                    dataHead.map((label, index) => (
                                    <TableCell
                                        key={label}
                                        align="center"
                                        style={{
                                        fontWeight: 'bold',
                                        maxWidth: '200px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        }}
                                    >
                                        <Tooltip title={label} placement="top">
                                        <span style={{ fontWeight: 'bold' }}>{label}</span>
                                        </Tooltip>
                                    </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataBody2
                                .map((row, rowIndex) => (
                                    <TableRow key={rowIndex} sx={{ padding: '8px' }}>
                                    {row.map((cell, cellIndex) => {
                                        if (cellIndex === 1) {
                                        return (
                                            <TableCell
                                            key={cellIndex}
                                            style={{
                                                maxWidth: '200px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                textAlign: 'center',
                                            }}
                                            >
                                            <Tooltip title={cell} placement="top">
                                                {cell === 'RECHAZADO' && <span className="pings-dot red"></span>}
                                                {cell === 'APROBADO' && <span className="pings-dot green"></span>}
                                            </Tooltip>
                                            </TableCell>
                                        );
                                        } else if (cellIndex === 3) {
                                        return (
                                            <TableCell
                                            key={cellIndex}
                                            style={{
                                                maxWidth: '200px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                            >
                                            <Tooltip title={cell} placement="top">
                                                <CellContent>{cell}</CellContent>
                                            </Tooltip>
                                            </TableCell>
                                        );
                                        } else {
                                        return (
                                            <TableCell
                                            key={cellIndex}
                                            style={{
                                                maxWidth: '200px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                textAlign: 'center',
                                            }}
                                            >
                                            <Tooltip title={cell} placement="top">
                                                <CellContent>{cell}</CellContent>
                                            </Tooltip>
                                            </TableCell>
                                        );
                                        }
                                    })}
                                    </TableRow>
                                ))}
                            </TableBody>
                            </Table>
                        </TableContainer>
                        </TableWrapper>
                    ) : (
                        <div></div>
                    )}
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px', alignItems: 'center' }}>
                <Button
                    sx={{ height: 40 }}
                    onClick={handleDownloadPNG2}
                    className="gestion_acceso-content-buttonArea-button"
                    variant="contained"
                >
                    {loadingData ? 'Descargando...' : 'Descargar imagen sitios'}
                </Button>
                </div>
                    </div>
                </div>
            </div>
            
            <Grid container spacing={2} sx={{ marginBottom: 5, marginTop: 2 }}>
                
                
                <Grid item xs={8} >
                    <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ marginBottom: '25px', marginTop: '50px' }}>
                            <div className="chart-container" ref={recurrenciasRef}>
                                <HighchartsReact highcharts={Highcharts} options={SITES_PC} ></HighchartsReact>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px', alignItems: 'center' }}>
                                <Button
                                sx={{ height: 40 }}
                                onClick={handleDownloadRecurrencias}
                                className="gestion_acceso-content-buttonArea-button"
                                variant="contained"
                                >
                                {loadingData ? 'Descargando...' : 'Descargar Gráfico'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {/* Tu contenido centrado dentro de la primera mitad */}
                        <div style={{ width: '90%', marginBottom: '25px' }}>
                        {dataBodyRechazosPC != null ? (
                            <TableWrapper>
                            <TableContainer 
                                component={Paper} 
                                style={{ maxHeight: '450px', overflowY: 'scroll' }}  // Limitar la altura y permitir el scroll
                            >
                                <Table stickyHeader ref={tableRefRechazosPC}>
                                <TableHead>
                                    <TableRow>
                                    {dataHeadRechazosPC &&
                                        dataHeadRechazosPC.map((label, index) => (
                                        <TableCell
                                            key={label}
                                            align="center"
                                            style={{
                                            fontWeight: 'bold',
                                            maxWidth: '200px',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            }}
                                        >
                                            <Tooltip title={label} placement="top">
                                            <span style={{ fontWeight: 'bold' }}>{label}</span>
                                            </Tooltip>
                                        </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataBodyRechazosPC
                                    .map((row, rowIndex) => (
                                        <TableRow key={rowIndex} sx={{ padding: '8px' }}>
                                        {row.map((cell, cellIndex) => {
                                            return (
                                            <TableCell
                                                key={cellIndex}
                                                style={{
                                                maxWidth: '200px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                textAlign: 'center',
                                                }}
                                            >
                                                <Tooltip title={cell} placement="top">
                                                <CellContent>{cell}</CellContent>
                                                </Tooltip>
                                            </TableCell>
                                            );
                                        })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                                </Table>
                            </TableContainer>
                            </TableWrapper>
                        ) : (
                            <div></div>
                        )}
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px', alignItems: 'center' }}>
                            <Button
                            sx={{ height: 40 }}
                            onClick={handleDownloadPNGRechazosPC}
                            className="gestion_acceso-content-buttonArea-button"
                            variant="contained"
                            >
                            {loadingData ? 'Descargando...' : 'Descargar imagen sitios'}
                            </Button>
                        </div>
                        </div>
                    </div>
                    </Grid>
            </Grid>


            </ThemeProvider>
        </div >
    )
}

export default Gestion_acceso;